import { t } from "@lingui/core/macro";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { Loading } from "@components/Loading/Loading";
import { useCallback, useEffect } from "react";
import { hasAuthParams, useAuth } from "react-oidc-context";
import * as Sentry from "@sentry/react";
import { deducedError } from "@/Utils/ErrorUtils";

const RequireAuth = ({
  children,
}: {
  children: React.ReactNode;
  acceptedRoles?: string[];
}) => {
  const {
    error,
    isAuthenticated,
    activeNavigator,
    isLoading,
    signinRedirect,
    events,
  } = useAuth();

  const signin = useCallback(() => {
    signinRedirect({
      acr_values: "idp:aad",
      scope: "IdentityServerApi create.otp",
      state: window.location.pathname,
    });
  }, [signinRedirect]);

  // automatically sign-in
  useEffect(() => {
    if (
      !hasAuthParams() &&
      !isAuthenticated &&
      !activeNavigator &&
      !isLoading
    ) {
      signin();
    }
  }, [isAuthenticated, activeNavigator, isLoading, signin]);

  // Token can expire while the app is in background
  // Forcefully sign in again if this happens
  useEffect(() => {
    return events.addAccessTokenExpired(signin);
  }, [events, signin]);

  if (activeNavigator || isLoading) {
    return <Loading message={t`Verifierar behörighet`} padding={24} />;
  }

  if (isAuthenticated) {
    return children;
  }

  if (error) {
    Sentry.captureException(error);
    return (
      <ErrorMessage
        message={`${t`Du har inte behörighet.`} ${deducedError(error)}`}
        padding={24}
      />
    );
  }

  return null;
};

export default RequireAuth;
