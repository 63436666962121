import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import {
  dischargePatient,
  patientKeys,
  useParentRoutesPatient,
} from "@/api/Patients";
import { FilledButton } from "@components/Button/Button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deducedError, displayErrorMessageAlert } from "@/Utils/ErrorUtils";
import Form from "@/components/Form/Form";
import { useForm } from "react-hook-form";
import Checkbox from "@/components/Checkbox/Checkbox";
import styles from "./Discharge.module.scss";
import type { IPatient } from "@models/patients";
import { canDischargePatient, patientStatusSchema } from "@models/patients";

const isDischarged = ({ status }: Pick<IPatient, "status">) =>
  status === patientStatusSchema.Values.discharged;

const Discharge = () => {
  const patient = useParentRoutesPatient();
  const patientName = patient.name;
  const queryClient = useQueryClient();
  const { mutate: dischargePatientMutation, isPending: isDischargingPatient } =
    useMutation({
      mutationFn: ({ patientId }: { patientId: string }) =>
        dischargePatient(patientId),
      onError: (error) => {
        displayErrorMessageAlert(
          `${t`Gick inte att skriva ut patienten.`} ${deducedError(error)}`,
        );
      },
      onSuccess: (_, { patientId }) => {
        return queryClient.invalidateQueries({
          queryKey: patientKeys.detail(patientId),
        });
      },
    });

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm({
    defaultValues: {
      cancelledScheduledActivities: false,
    },
  });

  const validateAndSubmit = handleSubmit(() => {
    return window.confirm(t`Är du säker på att du vill skriva ut patienten?`)
      ? dischargePatientMutation({ patientId: patient.id })
      : null;
  });

  if (canDischargePatient(patient)) {
    return (
      <div className={styles.dischargedWrapper}>
        <Form onSubmit={validateAndSubmit}>
          <Checkbox
            errorMessage={errors.cancelledScheduledActivities?.message}
            label={{ text: t`Patientens aktiviteter är avslutade` }}
            {...register("cancelledScheduledActivities", {
              required: {
                value: true,
                message: t`Bekräfta att aktiviteterna har avslutats`,
              },
            })}
          />
          <FilledButton
            disabled={isDischargingPatient}
            type="submit"
          >{t`Skriv ut ${patientName}`}</FilledButton>
        </Form>
      </div>
    );
  }

  if (isDischarged(patient)) {
    return (
      <div className={styles.dischargedWrapper}>
        <p>{t`${patientName} är utskriven`}</p>
      </div>
    );
  }

  return (
    <p className={styles.dischargedWrapper}>
      <Trans>Denna patient kan inte skrivas ut</Trans>
    </p>
  );
};

export { Discharge };
