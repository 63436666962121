import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { Heading } from "@components/Heading/Heading";
import { IconButton } from "@components/Button/Button";
import { useNavigate } from "react-router";
import styles from "./AddActivity.module.scss";
import CrossIcon from "@components/icons/CrossIcon";
import { Outlet } from "react-router";

export const AddActivity = () => {
  const navigate = useNavigate();

  return (
    <section className={styles.addActivity}>
      <div className={styles.heading}>
        <Heading level="h2">
          <Trans>Anpassad aktivitet</Trans>
        </Heading>
        <IconButton
          aria-label={t`Stäng formuläret`}
          onClick={() => navigate(`..`)}
        >
          <CrossIcon />
        </IconButton>
      </div>
      <div className={styles.tabContent}>
        <Outlet />
      </div>
    </section>
  );
};
