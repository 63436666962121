import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { z } from "zod";
import { usePatient } from "@/api/Patients";
import { useParams } from "react-router";
import styles from "./Information.module.scss";
import { Loading } from "@components/Loading/Loading";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { deducedError } from "@/Utils/ErrorUtils";
import { formatPostalCodeWithSpace } from "@/Utils/postalCodeUtils";
import { NavCard } from "@/components/NavCard/NavCard";
import { Heading } from "@components/Heading/Heading";
import * as Sentry from "@sentry/react";

export const Information = () => {
  const { patientId } = z.object({ patientId: z.string() }).parse(useParams());
  const { data: patient, isPending, isError, error } = usePatient(patientId);

  if (isPending) {
    return <Loading message={t`Laddar patientinformation`} padding={24} />;
  }

  if (isError) {
    Sentry.captureException(error);
    return <ErrorMessage message={deducedError(error)} padding={24} />;
  }

  const {
    name: patientName,
    address,
    phoneNumber,
    relatives,
    information,
    safetyAlarmText,
  } = patient;

  return (
    <section className={styles.infoSection}>
      {/* ADDRESS */}
      <NavCard
        title={
          <Heading level="h2">
            <Trans>Adress</Trans>
          </Heading>
        }
        titleWeight="bold"
        to="edit-address"
      >
        <p>
          {address.addressLine1}
          <br />
          {formatPostalCodeWithSpace(address.postalCode)} {address.city}
        </p>
        {address.additionalInformation ? (
          <ul>
            {address.additionalInformation
              ?.split("\n")
              .map((item, index) => <li key={item + index}>{item}</li>)}
          </ul>
        ) : (
          <></>
        )}
      </NavCard>

      {/* PHONE */}
      <NavCard
        title={
          <Heading level="h2">
            <Trans>Telefonnummer</Trans>
          </Heading>
        }
        titleWeight="bold"
        to="edit-phone-number"
      >
        <p>{phoneNumber}</p>
      </NavCard>

      {/* OTHER INFORMATION */}
      <NavCard
        title={
          <Heading level="h2">
            <Trans>Allmän information</Trans>
          </Heading>
        }
        titleWeight="bold"
        to="edit-other-information"
      >
        {information ? (
          <ul>
            {information
              ?.split("\n")
              .map((item, index) => <li key={item + index}>{item}</li>)}
          </ul>
        ) : (
          <p>-</p>
        )}
      </NavCard>

      {/* RELATIVES */}
      <NavCard
        title={
          <Heading level="h2">
            <Trans>Anhöriga</Trans>
          </Heading>
        }
        titleWeight="bold"
        to="edit-relatives"
      >
        {relatives.map(
          ({ id, name, relation, isLivingTogether, phoneNumber }) => {
            const optionalLivesTogetherWithSegment = isLivingTogether
              ? t`bor med ${patientName} och`
              : "";
            return (
              <p key={id}>
                <Trans>
                  {name} ({relation}) {optionalLivesTogetherWithSegment} nås på{" "}
                  {phoneNumber}
                </Trans>
              </p>
            );
          },
        )}
      </NavCard>

      {/* SAFETY ALARM */}
      <NavCard
        title={
          <Heading level="h2">
            <Trans>Trygghetslarm</Trans>
          </Heading>
        }
        titleWeight="bold"
        to="edit-safety-alarm"
      >
        <p>{safetyAlarmText}</p>
      </NavCard>
    </section>
  );
};
