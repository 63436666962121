import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import Select from "@/components/Select/Select";
import {
  healthCareProcedureCodes,
  type IPatientTypeForInvoicing,
  productCodes,
} from "@/api/Invoicing";
import { useLingui } from "@lingui/react";
import { patientTypeSchema } from "@models/patients";

export const InvoicingPicker = ({
  patientType,
}: {
  patientType: IPatientTypeForInvoicing;
}) => {
  const { _ } = useLingui();
  return (
    <>
      <Select label={t`Åtgärd (KVÅ)`} name={t`Åtgärd (KVÅ)`}>
        <option value="">
          <Trans>Ingen</Trans>
        </option>
        {healthCareProcedureCodes[patientType].map((actionCode) => (
          <option key={actionCode.code} value={actionCode.code}>
            {`${actionCode.code}, ${_(actionCode.name)}`}
          </option>
        ))}
      </Select>
      {patientType === patientTypeSchema.Values.External ? (
        <Select name={t`Produkt`} label={t`Produkt`}>
          <option value="">
            <Trans>Ingen</Trans>
          </option>
          {productCodes.External.map((productCode) => (
            <option key={productCode.code} value={productCode.code}>
              {`${productCode.code}, ${_(productCode.name)}`}
            </option>
          ))}
        </Select>
      ) : null}
    </>
  );
};
