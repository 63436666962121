import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { ANY_TIME_OF_DAY, useActivity } from "@/api/Activities";
import styles from "./ActivitySchedule.module.scss";
import { Fragment } from "react/jsx-runtime";
import { EditButton } from "./EditButton";
import { Heading } from "@components/Heading/Heading";
import { EditSchedule } from "./EditSchedule";
import { timeOfDayDictionary } from "@models/activities";
import { knownFeatureFlagsSchema, useFeatureFlag } from "@/api/FeatureFlags";
import { addTime } from "@/Utils/TimeUtils";
import { useCurrentlyEditing } from "./useCurrentlyEditing";
import { useNavigate } from "react-router";
import { EditContainer } from "./EditContainer";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { Loading } from "@components/Loading/Loading";
import { deducedError } from "@/Utils/ErrorUtils";
import * as Sentry from "@sentry/react";
import { useLingui } from "@lingui/react";
import {
  recurringDictionary,
  recurringNumberDictionary,
} from "@/forms/AddActivityForm/Scheduling/recurrenceUtils";
import { weekdayDictionary } from "@/forms/AddActivityForm/Scheduling/weekdaysUtils";

const TimeSpan = ({ start, timespan }: { start: string; timespan: string }) => {
  const end = addTime(start, timespan);
  return <time>{`${start}-${end}`}</time>;
};

export const ActivitySchedule = ({ activityId }: { activityId: string }) => {
  const { data: activity, isPending, isError, error } = useActivity(activityId);
  const { currentlyEditing, setCurrentlyEditing } = useCurrentlyEditing();
  const { data: disableChangeRecurrence } = useFeatureFlag(
    knownFeatureFlagsSchema.Values.DisableChangeRecurrence,
  );
  const navigate = useNavigate();

  if (isPending) {
    return <Loading message={t`Hämtar aktivitet`} padding={24} />;
  }

  if (isError) {
    Sentry.captureException(error);
    return <ErrorMessage message={deducedError(error)} padding={24} />;
  }

  const schedule = activity.schedule;
  const hasIntervalRecurrence = "interval" in schedule;
  const hasWeekdaysRecurrence = "days" in schedule;

  const Frequency = () => {
    const { _ } = useLingui();
    if (hasIntervalRecurrence) {
      const numberOfDays = schedule.interval;
      const recurrencePattern = recurringNumberDictionary[schedule.interval];
      return (
        <p>
          {recurrencePattern
            ? _(recurringDictionary[recurrencePattern])
            : t`${numberOfDays} dagars intervall`}
        </p>
      );
    } else if (hasWeekdaysRecurrence) {
      return (
        <p>
          {schedule.days
            .map((day) => _(weekdayDictionary[day].regular))
            .join(", ")}
        </p>
      );
    } else {
      return <></>;
    }
  };

  const Times = () => {
    const { _ } = useLingui();
    if ("times" in schedule) {
      if (schedule.times === ANY_TIME_OF_DAY) {
        return <p>{_(timeOfDayDictionary.Any.long)}</p>;
      } else {
        return (
          <p>
            {schedule.times.map((time, i) => {
              const divider = i === schedule.times.length - 1 ? "" : ", ";
              return (
                <Fragment key={time}>
                  <TimeSpan start={time} timespan={schedule.span} />
                  {divider}
                </Fragment>
              );
            })}
          </p>
        );
      }
    } else {
      <></>;
    }
  };

  return (
    <section className={styles.informationSection}>
      <div className={styles.editableSection}>
        <Heading level="h3">
          <Trans>Upprepning</Trans>
        </Heading>
        {disableChangeRecurrence === false && (
          <EditButton
            attribute="edit-schedule"
            isEditing={currentlyEditing === "schedule"}
            toggleIsEditing={() =>
              setCurrentlyEditing(
                currentlyEditing === "schedule" ? null : "schedule",
              )
            }
          />
        )}
      </div>
      {currentlyEditing === "schedule" ? (
        <EditContainer>
          <EditSchedule
            activityId={activity.id}
            schedule={schedule}
            onSuccess={() => {
              navigate("..");
            }}
          />
        </EditContainer>
      ) : (
        <div>
          <Frequency />
          <Times />
        </div>
      )}
    </section>
  );
};
