import { RoutesContextProvider } from "./RoutesContextProvider";
import { DaySwitcher } from "@/components/DaySwitcher/DaySwitcher";
import { RoutesMap } from "./RoutesMap";
import { RoutesTabs } from "./RoutesTabs";
import styles from "./Routes.module.scss";
import { MapProvider } from "react-map-gl";

export const Routes = () => {
  return (
    <RoutesContextProvider>
      <MapProvider>
        <div className={styles.container}>
          <div className={styles.routesContainer}>
            <div className={styles.daySwitcher}>
              <DaySwitcher />
            </div>
            <div className={styles.routes}>
              <RoutesTabs />
            </div>
          </div>
          <div className={styles.map}>
            <RoutesMap />
          </div>
        </div>
      </MapProvider>
    </RoutesContextProvider>
  );
};
