import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import styles from "./Header.module.scss";
import { logout } from "@/Utils/InloggedUtils";
import Person from "@/components/Person/Person";
import { Loading, LoadingOverlay } from "@components/Loading/Loading";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { useUser } from "@/api/Users";
import { useMsal } from "@azure/msal-react";
import DropdownMenu from "@/components/DropdownMenu/DropdownMenu";
import LogoutIcon from "@components/icons/SVG/logout.svg?react";
import { Link, NavLink } from "react-router";
import LogoText from "@components/icons/LogoText";
import {
  SmsEmployeeNotificationDropdownToggleItem,
  SmsPatientNotificationDropdownToggleItem,
} from "@/components/SmsNotificationDropdownToggleItem/SmsNotificationDropdownToggleItem";
import { useCurrentEmployee } from "@/api/Employee";
import { DropdownMenuGroup } from "@radix-ui/react-dropdown-menu";
import { useContext, useEffect } from "react";
import * as Sentry from "@sentry/react";
import SentryFeedbackButton from "@/components/SentryFeedbackButton/SentryFeedbackButton";
import { deducedError } from "@/Utils/ErrorUtils";
import { useRoomNamesWithUnreadNotifications } from "@chat/useUnreadNotificationCounts";
import { ROOM_ALIAS_PREFIX_EMPLOYEE } from "@chat/matrixClient";
import UnreadChatMessagesIndicator from "@/components/IconWithIndicator/UnreadChatMessagesIndicator/UnreadChatMessagesIndicator";
import ChatContext from "@chat/ChatContext";
import { UnstyledLink } from "@components/Link/Link";
import NotificationCircle from "@components/NotificationCircle/NotificationCircle";
import ChatConversationIcon from "@components/icons/ChatConversation";
import { patientsWithUnhandledMeasurementsQueryOptions } from "@/api/Patients";
import { useQuery } from "@tanstack/react-query";
import UnhandledMeasurementsIndicator from "@/components/IconWithIndicator/UnhandledMeasurementsIndicator/UnhandledMeasurementsIndicator";
import { employeeName } from "@models/shifts";

const Navigation = () => {
  const { data: patientsWithUnhandledMeasurements } = useQuery(
    patientsWithUnhandledMeasurementsQueryOptions,
  );
  const roomNamesWithUnreadNotifications =
    useRoomNamesWithUnreadNotifications();
  const roomNamesWithUnreadNotificationsExceptEmployees =
    roomNamesWithUnreadNotifications.filter(
      (roomName) => !roomName.startsWith(ROOM_ALIAS_PREFIX_EMPLOYEE),
    );
  const patientRoomsHaveUnreadChatNotifications =
    roomNamesWithUnreadNotificationsExceptEmployees !== undefined &&
    roomNamesWithUnreadNotificationsExceptEmployees.length > 0;

  const patientsHaveUnhandledMeasurements =
    patientsWithUnhandledMeasurements !== undefined &&
    patientsWithUnhandledMeasurements.some(
      ({ unhandledMeasurementsCount }) => unhandledMeasurementsCount > 0,
    );

  return (
    <nav>
      <ul className={styles.navItems}>
        <li>
          <NavLink to="patients" className={styles.navItem}>
            <Trans>Patienter</Trans>
            <UnreadChatMessagesIndicator
              hasUnreadMessages={
                patientRoomsHaveUnreadChatNotifications ?? false
              }
            />
            <UnhandledMeasurementsIndicator
              hasUnhandledMeasurements={patientsHaveUnhandledMeasurements}
            />
          </NavLink>
        </li>
        <li>
          <NavLink to="activities" className={styles.navItem}>
            <Trans>Aktiviteter</Trans>
          </NavLink>
        </li>
        <li>
          <NavLink to="shifts" className={styles.navItem}>
            <Trans>Arbetspass</Trans>
          </NavLink>
        </li>
        <li>
          <NavLink to="routes" className={styles.navItem}>
            <Trans>Rutter</Trans>
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

const ChatLink = () => {
  const roomIdsWithUnreadNotifications = useRoomNamesWithUnreadNotifications();
  const employeeRoomsWithUnreadNotifications =
    roomIdsWithUnreadNotifications?.filter((id) =>
      id.startsWith(ROOM_ALIAS_PREFIX_EMPLOYEE),
    );

  const { matrixClient } = useContext(ChatContext);
  const hasCompletedInitialSync = !!matrixClient;

  const numberOfEmployeesWithUnreadNotifications =
    employeeRoomsWithUnreadNotifications?.length;

  return (
    <UnstyledLink to="chat">
      <div className={styles.iconLink}>
        <LoadingOverlay show={!hasCompletedInitialSync}>
          <NotificationCircle
            isVisible={!!numberOfEmployeesWithUnreadNotifications}
            placement="top-left"
            number={numberOfEmployeesWithUnreadNotifications}
          >
            <ChatConversationIcon />
          </NotificationCircle>
        </LoadingOverlay>
      </div>
    </UnstyledLink>
  );
};

const Header = () => {
  const { data: user, isPending, isError, error } = useUser();
  const { data: employee } = useCurrentEmployee();
  const { chatEnabled } = useContext(ChatContext);
  useEffect(() => {
    if (employee) {
      Sentry.setUser({
        username: employeeName(employee),
      });
    }
  }, [employee]);
  const { instance } = useMsal();

  const CustomTrigger = () => (
    <Person
      name={user?.givenName ?? ""}
      photoUrl={employee?.imageURL ?? null}
    />
  );

  const DisplayError = () => {
    Sentry.captureException(error);
    return (
      <ErrorMessage
        message={`${t`Kunde inte hämta användarprofil.`} ${deducedError(error)}`}
      />
    );
  };

  return (
    <header className={styles.header}>
      <Navigation />
      <div className={styles.logoContainer}>
        <Link aria-label={t`Startsida`} to="/" className={styles.logo}>
          <LogoText />
        </Link>
      </div>
      <div className={styles.rightContainer}>
        {chatEnabled ? <ChatLink /> : <></>}
        {isPending ? (
          <Loading message={t`Laddar användare`} />
        ) : isError ? (
          <DisplayError />
        ) : (
          <DropdownMenu
            trigger={{
              custom: <CustomTrigger />,
              ariaLabel: t`Meny`,
            }}
          >
            {chatEnabled ? (
              <>
                <DropdownMenuGroup>
                  <DropdownMenu.Label>
                    <Trans>SMS-notiser för chatt</Trans>
                  </DropdownMenu.Label>
                  <SmsPatientNotificationDropdownToggleItem />
                  <SmsEmployeeNotificationDropdownToggleItem />
                </DropdownMenuGroup>
                <DropdownMenu.Separator />
              </>
            ) : (
              <></>
            )}
            <DropdownMenu.Item content={<SentryFeedbackButton />} />
            <DropdownMenu.Separator />
            <DropdownMenu.Item
              action={() => logout(instance)}
              content={
                <>
                  <LogoutIcon /> <Trans>Logga ut</Trans>
                </>
              }
            />
          </DropdownMenu>
        )}
      </div>
    </header>
  );
};

export default Header;
