import { msg } from "@lingui/core/macro";
import type { IWeekday } from "@/api/Activities";
import type { MessageDescriptor } from "@lingui/core";

export const weekdayDictionary: Record<
  IWeekday,
  { short: MessageDescriptor; regular: MessageDescriptor }
> = {
  Monday: { short: msg`Mån`, regular: msg`Måndag` },
  Tuesday: { short: msg`Tis`, regular: msg`Tisdag` },
  Wednesday: { short: msg`Ons`, regular: msg`Onsdag` },
  Thursday: { short: msg`Tors`, regular: msg`Torsdag` },
  Friday: { short: msg`Fre`, regular: msg`Fredag` },
  Saturday: { short: msg`Lör`, regular: msg`Lördag` },
  Sunday: { short: msg`Sön`, regular: msg`Söndag` },
};
