import { t } from "@lingui/core/macro";
import { useState } from "react";
import { displayErrorMessageAlert } from "@/Utils/ErrorUtils";
import { InlineAlert } from "@components/InlineAlert/InlineAlert";
import styles from "./NotificationPermitter.module.scss";

const useNotificationPermission = () => {
  const [notificationPermission, setNotificationPermission] = useState(
    Notification.permission,
  );

  const requestPermission = () =>
    Notification.requestPermission().then(setNotificationPermission);

  return { notificationPermission, requestPermission };
};

const NotificationPermitter = () => {
  const { notificationPermission, requestPermission } =
    useNotificationPermission();
  return notificationPermission === "granted" ? null : (
    <div className={styles.notificationPermitter}>
      <InlineAlert
        type="warning"
        title={t`Tillåt aviseringar`}
        message={t`Tryck på knappen för att tillåta aviseringar från chattmeddelanden och andra händelser`}
        placement="fill"
        actions={[
          {
            label: t`Tillåt aviseringar`,
            onClick: () =>
              notificationPermission === "denied"
                ? displayErrorMessageAlert(
                    t`Du har blockerat aviseringar. Klicka på ikonen till vänster om webbadressen för att åtgärda detta.`,
                  )
                : requestPermission(),
            isPrimary: true,
          },
        ]}
      />
    </div>
  );
};

export { NotificationPermitter };
