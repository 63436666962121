import { Trans } from "@lingui/react/macro";
import { ActivitiesTable } from "./ActivitiesTable";
import styles from "./index.module.scss";
import { PlainButton } from "@components/Button/Button";
import { WorkBlocks } from "./WorkBlocks";
import PlusIcon from "@components/icons/PlusIcon";
import { DaySwitcher } from "@/components/DaySwitcher/DaySwitcher";
import { useNavigate } from "react-router";

const ActivitiesHeader = () => {
  const navigate = useNavigate();
  return (
    <header className={styles.activitiesHeader}>
      <DaySwitcher />
      <PlainButton onClick={() => navigate(`new`)}>
        <PlusIcon />
        <Trans>Ny aktivitet</Trans>
      </PlainButton>
    </header>
  );
};

export const Activities = () => {
  return (
    <div className={styles.root}>
      <section className={styles.container}>
        <ActivitiesHeader />
        <ActivitiesTable />
      </section>
      <div className={styles.widgets}>
        <section className={styles.container}>
          <WorkBlocks />
        </section>
      </div>
    </div>
  );
};
