import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { patientKeys, updateArea, useAreas } from "@/api/Patients";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { FilledButton } from "@components/Button/Button";
import Form from "@/components/Form/Form";
import { Loading } from "@components/Loading/Loading";
import { deducedError } from "@/Utils/ErrorUtils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import type { IArea } from "@models/patients";
import Select from "@/components/Select/Select";
import styles from "./EditArea.module.scss";

export const EditArea = ({
  currentArea,
  onSuccess,
  patientId,
}: {
  currentArea: IArea | undefined | null;
  onSuccess: () => void;
  patientId: string;
}) => {
  const currentAreaIdAsString = currentArea ? currentArea.id.toString() : null;
  const {
    formState: { errors, isDirty },
    getValues,
    handleSubmit,
    register,
    reset,
    setError,
  } = useForm({
    defaultValues: {
      areaId: currentAreaIdAsString,
    },
  });
  const queryClient = useQueryClient();
  const {
    data: areas,
    isPending: isPendingAreas,
    isError: isErrorAreas,
  } = useAreas();
  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: (area: number | null) => updateArea({ patientId, area }),
    onError: (error) => {
      setError("root.server", {
        message: deducedError(error),
      });
      // Reset `isDirty` to support only showing server error when the form is not changed.
      reset(getValues(), {
        keepErrors: true,
        keepIsSubmitted: true,
        keepTouched: true,
        keepIsValid: true,
        keepSubmitCount: true,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: patientKeys.detail(patientId),
      });
      onSuccess();
    },
  });

  return (
    <Form
      onSubmit={handleSubmit((formData) => {
        if (formData.areaId) {
          mutate(Number(formData.areaId));
        } else {
          mutate(null);
        }
      })}
    >
      {isPending || isSuccess ? (
        <Loading message={t`Ändrar område`} />
      ) : (
        <>
          {errors.root?.server?.message && !isDirty ? (
            <ErrorMessage message={errors.root.server.message} />
          ) : undefined}
          <Form.Row>
            <div className={styles.select}>
              {isPendingAreas ? (
                <Loading message={t`Hämtar områden`} />
              ) : isErrorAreas ? (
                <ErrorMessage message={t`Kunde inte hämta områden`} />
              ) : (
                <Select
                  label=""
                  {...register("areaId")}
                  errorMessage={errors.areaId?.message}
                >
                  <option value={""}>{t`Inget`}</option>
                  {areas.map((area) => (
                    <option value={area.id.toString()} key={area.id}>
                      {area.displayName}
                    </option>
                  ))}
                </Select>
              )}
            </div>
          </Form.Row>
          {isDirty ? (
            <FilledButton type="submit">
              <Trans>Spara ändringar</Trans>
            </FilledButton>
          ) : (
            <></>
          )}
        </>
      )}
    </Form>
  );
};
