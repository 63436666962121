import clsx from "clsx";
import styles from "./StatusTag.module.scss";
import CheckFilledIcon from "@components/icons/CheckFilledIcon";
import ArrowRightFilledIcon from "@components/icons/ArrowRightFilledIcon";
import type { IStatus } from "./statusTagUtils";
import { statusDictionary } from "./statusTagUtils";
import { Tag, type ITag } from "@components/Tag/Tag";
import { useLingui } from "@lingui/react";
import { i18n } from "@lingui/core";
import { visitStatusSchema } from "@models/activities";

export type IStatusTag = {
  status: IStatus;
  timestamp?: Date;
} & Partial<Pick<ITag, "variant" | "size">>;

const statusToColorMap: Record<
  IStatus,
  "gray" | "blue" | "yellow" | "green" | "red"
> = {
  notReady: "gray",
  ready: "blue",
  planned: "blue",
  travellingTo: "blue",
  ongoing: "yellow",
  finished: "green",
  expired: "red",
};
export const StatusTag = ({
  status,
  variant = "icon",
  size = "small",
  timestamp,
}: IStatusTag) => {
  const { _ } = useLingui();
  const label = _(statusDictionary[status]);

  const Icon = () => (
    <div className={styles.iconWrapper}>
      <div className={clsx(styles.icon, styles[`icon-${status}`])}>
        {status === visitStatusSchema.Values.travellingTo ? (
          <ArrowRightFilledIcon />
        ) : null}
        {status === "finished" ? <CheckFilledIcon /> : null}
      </div>
    </div>
  );

  return (
    <Tag
      icon={<Icon />}
      color={statusToColorMap[status]}
      localizedTimestamp={
        timestamp ? i18n.date(timestamp, { timeStyle: "short" }) : undefined
      }
      label={label}
      size={size}
      variant={variant}
    />
  );
};
