import { t } from "@lingui/core/macro";
import { useFormContext } from "react-hook-form";
import Checkbox from "@/components/Checkbox/Checkbox";
import Checkboxes from "@/components/Checkbox/Checkboxes";
import type { IMedicalCompetence } from "@models/shifts";
import {
  medicalCompetenceDictionary,
  medicalCompetenceSchema,
} from "@models/shifts";
import { useLingui } from "@lingui/react";

export type ICompetencePickerFields = {
  requiredCompetences: IMedicalCompetence[];
};

const CompetencePicker = ({ isRequired = false }: { isRequired?: boolean }) => {
  const {
    formState: { errors },
    register,
  } = useFormContext<ICompetencePickerFields>();
  const { _ } = useLingui();
  return (
    <Checkboxes
      legend={isRequired ? t`Kompetensbehov` : t`Kompetensbehov (valfri)`}
      errorMessage={errors.requiredCompetences?.message}
      orientation="horizontal"
    >
      {/* Only one Checkbox has to hold additional logic, applies to whole registration. */}
      <Checkbox
        label={{ text: _(medicalCompetenceDictionary.NurseAssistant.short) }}
        {...register(`requiredCompetences`, {
          required: {
            value: isRequired,
            message: t`Minst en kompetens behöver anges`,
          },
        })}
        value={medicalCompetenceSchema.Values.NurseAssistant}
        visualStyle="framed"
      />
      <Checkbox
        label={{ text: _(medicalCompetenceDictionary.NursePractitioner.short) }}
        {...register(`requiredCompetences`)}
        value={medicalCompetenceSchema.Values.NursePractitioner}
        visualStyle="framed"
      />
      <Checkbox
        label={{ text: _(medicalCompetenceDictionary.MedicalDoctor.short) }}
        {...register(`requiredCompetences`)}
        value={medicalCompetenceSchema.Values.MedicalDoctor}
        visualStyle="framed"
      />
      <Checkbox
        label={{ text: _(medicalCompetenceDictionary.Support.long) }}
        {...register(`requiredCompetences`)}
        value={medicalCompetenceSchema.Values.Support}
        visualStyle="framed"
      />
    </Checkboxes>
  );
};

export { CompetencePicker };
