import { NavLink } from "react-router";
import type { To } from "react-router";
import styles from "./Tab.module.scss";

interface ITab {
  children: React.ReactNode;
  onClick?(): void;
  to?: To;
}

const Tab = ({ children, onClick, to }: ITab) => {
  return to ? (
    <NavLink to={to} className={styles.tab} onClick={onClick}>
      {children}
    </NavLink>
  ) : (
    <button role="tab" type="button" className={styles.tab} onClick={onClick}>
      {children}
    </button>
  );
};

export default Tab;
