import clsx from "clsx";
import { forwardRef, useId } from "react";
import styles from "./RadioButton.module.scss";

interface IRadioButton {
  disabled?: boolean;
  name: string;
  label: {
    text: string;
    component?: React.ReactNode;
  };
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  value: string;
  visualStyle?: "normal" | "framed" | "noRadioCircle";
  radioPosition?: "center" | "top";
}

const RadioButton = forwardRef<HTMLInputElement | null, IRadioButton>(
  (
    {
      disabled,
      name: groupName,
      label,
      value,
      visualStyle = "normal",
      radioPosition = visualStyle === "normal" ? "top" : "center",
      ...rest
    },
    ref,
  ) => {
    const id = useId();

    return (
      <div
        className={clsx(
          styles.radioButtonWrapper,
          styles[`visualStyle-${visualStyle}`],
          styles[`radioPosition-${radioPosition}`],
        )}
      >
        <label htmlFor={id} className={clsx(disabled && styles.disabled)}>
          <span className={label.component ? "visuallyHidden" : undefined}>
            {label.text}
          </span>
          {label.component}
        </label>
        <input
          type="radio"
          aria-label={label.component !== undefined ? label.text : undefined}
          id={id}
          name={groupName}
          ref={ref}
          value={value}
          className={styles.radioButton}
          disabled={disabled}
          {...rest}
        />
      </div>
    );
  },
);
RadioButton.displayName = "RadioButton";

export default RadioButton;
