import { useState } from "react";
import { ShiftsContext } from "./ShiftsContext";
import type { ISelectedItem } from "./ShiftsRow";
import type { IActivityOccurrenceOrGroup } from "@models/activities";

export const ShiftsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [draggedItem, setDraggedItem] = useState<
    IActivityOccurrenceOrGroup | undefined
  >(undefined);
  const [selectedItem, setSelectedItem] = useState<ISelectedItem | undefined>(
    undefined,
  );
  const [boundsShowingAllPatients, setBoundsShowingAllPatients] = useState<
    | readonly [{ lat: number; lng: number }, { lat: number; lng: number }]
    | undefined
  >(undefined);

  return (
    <ShiftsContext.Provider
      value={{
        draggedItem,
        setDraggedItem,
        selectedItem,
        setSelectedItem,
        boundsShowingAllPatients,
        setBoundsShowingAllPatients,
      }}
    >
      {children}
    </ShiftsContext.Provider>
  );
};
