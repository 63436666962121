import { t } from "@lingui/core/macro";
import {
  notificationKeys,
  setSmsNotificationStatus,
  useSmsNotificationStatus,
} from "@/api/SmsNotifications";
import DropdownMenu from "../DropdownMenu/DropdownMenu";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { displayErrorMessageAlert } from "@/Utils/ErrorUtils";

export const SmsPatientNotificationDropdownToggleItem = () => {
  const {
    data: smsNotificationStatus,
    isPending: isPendingSmsNotificationStatus,
  } = useSmsNotificationStatus();
  const queryClient = useQueryClient();
  const { mutate, isPending: isUpdatingSmsNotificationStatus } = useMutation({
    mutationFn: ({ status }: { status: boolean }) =>
      setSmsNotificationStatus("patient", status),
    onError: (_, { status }) => {
      const activateOrDeactivate = status ? "aktivera" : "avaktivera";
      displayErrorMessageAlert(
        t`Gick inte att ${activateOrDeactivate} SMS-notiser för patient. Försök igen om en stund, och rapportera ett fel om det ändå inte fungerar.`,
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: notificationKeys.sms() });
    },
  });
  return (
    <DropdownMenu.ToggleItem
      toggledOn={
        smsNotificationStatus?.notificationConfig.patientSmsNotification
      }
      disabled={
        isUpdatingSmsNotificationStatus || isPendingSmsNotificationStatus
      }
      onToggle={(newStatus) => {
        mutate({ status: newStatus });
      }}
      label={t`Patienter`}
    />
  );
};

export const SmsEmployeeNotificationDropdownToggleItem = () => {
  const {
    data: smsNotificationStatus,
    isPending: isPendingSmsNotificationStatus,
  } = useSmsNotificationStatus();
  const queryClient = useQueryClient();
  const { mutate, isPending: isUpdatingSmsNotificationStatus } = useMutation({
    mutationFn: ({ status }: { status: boolean }) =>
      setSmsNotificationStatus("employee", status),
    onError: (_, { status }) => {
      const activateOrDeactivate = status ? "aktivera" : "avaktivera";
      displayErrorMessageAlert(
        t`Gick inte att ${
          activateOrDeactivate
        } SMS-notiser för personal. Försök igen om en stund, och rapportera ett fel om det ändå inte fungerar.`,
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: notificationKeys.sms() });
    },
  });
  return (
    <DropdownMenu.ToggleItem
      toggledOn={
        smsNotificationStatus?.notificationConfig.employeeSmsNotification
      }
      disabled={
        isUpdatingSmsNotificationStatus || isPendingSmsNotificationStatus
      }
      onToggle={(newStatus) => {
        mutate({ status: newStatus });
      }}
      label={t`Kollegor`}
    />
  );
};
