import { Trans } from "@lingui/react/macro";
import { Tab, TabPanel, TabList } from "@components/Tabs";
import styles from "./index.module.scss";
import { Outlet } from "react-router";
import { useOtherEmployeeActivityOccurrences } from "@/api/Activities";
import { fetchEmployeesRoutes, routeKeys } from "@/api/Routes";
import { startOfToday } from "date-fns";
import { useQuery } from "@tanstack/react-query";
import { format } from "@models/date-and-time";
import { categorySchema } from "@models/activities";

export const TabsView = () => {
  const { data: activityOccurrences } = useOtherEmployeeActivityOccurrences();

  const today = format(startOfToday(), "yyyy-MM-dd");
  const { data: myRoutes } = useQuery({
    queryKey: routeKeys.list({
      from: today,
      to: null,
      assignee: "me",
    }),
    queryFn: () => {
      return fetchEmployeesRoutes(today);
    },
  });

  const numberOfUnfinishedHomeVisits =
    myRoutes?.flat().reduce((acc, route) => {
      const visitsCount = route.visits?.filter(
        (visit) => visit.status !== "finished",
      ).length;
      return acc + visitsCount;
    }, 0) ?? "-";

  const numberOfUnfinishedOtherActivities =
    activityOccurrences?.filter((activityOccurrence) => {
      const { category, status } = activityOccurrence;
      return (
        (category === categorySchema.Values.AdminTask ||
          category === categorySchema.Values.VideoCall) &&
        status !== "finished"
      );
    }).length ?? "-";

  return (
    <>
      <TabList>
        <Tab to="routes">
          <Trans>Hembesök ({numberOfUnfinishedHomeVisits})</Trans>
        </Tab>
        <Tab to="other-activities">
          <Trans>Övriga ({numberOfUnfinishedOtherActivities})</Trans>
        </Tab>
      </TabList>
      <div className={styles.tabPanelWrapper}>
        <TabPanel>
          <Outlet />
        </TabPanel>
      </div>
    </>
  );
};
