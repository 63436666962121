import { msg } from "@lingui/core/macro";
import { templateSuggestions } from "@/pages/commandcenter/Patients/Patient/Activities/AddActivity/Templates/templates";
import type { MessageDescriptor } from "@lingui/core";
import { z } from "zod";

const homeVisitSuggestions = [
  msg`Aktivitetsregim`,
  msg`Andningsbedömning`,
  msg`Andningsgymnastik`,
  msg`Asctiesdränage`,
  msg`Basal omvårdnad`,
  msg`Bladderscan`,
  msg`Blodprov`,
  msg`Blodtransfusion`,
  msg`Byte av pump`,
  msg`Byte larmklocka`,
  msg`Byte materiallåda`,
  msg`Byte PVK - perfier infart`,
  msg`Byte Sensorem`,
  msg`CVK - central infart`,
  msg`Dra infarter`,
  msg`Dusch`,
  msg`EKG`,
  msg`Funktionsbedömning`,
  msg`Förskrvining av hjälpmedel`,
  msg`Fysisk undersökning`,
  msg`Förflyttningsbedömmning`,
  msg`Hämta hjälpmedel`,
  msg`Holter-EKG/telemetri`,
  msg`Hjälpmedelsbedömning`,
  msg`Hjälpmedelsutprovning/utlämning`,
  msg`Hämtning larmklocka`,
  msg`Hämtning materiallåda`,
  msg`Hämtning tekniklåda`,
  msg`Infusion dropp`,
  msg`Koppla pump`,
  msg`Introbesök hos patient`,
  msg`KAD - kateter`,
  msg`Kapillär provtagning`,
  msg`Kontroll infart`,
  msg`Koppla bort pump`,
  msg`Leverans larmklocka`,
  msg`Leverans läkemedel`,
  msg`Leverans mat`,
  msg`Leverans materiallåda`,
  msg`Läkemedel subkutan injektion`,
  msg`Läkemedel intravenöst`,
  msg`Medicin per oral`,
  msg`Medicinsk apparatur i hemmet`,
  msg`Midline`,
  msg`Nyckelkvittens`,
  msg`Omläggning central infart`,
  msg`Omläggning dränage`,
  msg`Omläggning perifer infart`,
  msg`Patientenkät`,
  msg`Picc-line`,
  msg`Pleuradränage`,
  msg`PVK - perifer infart`,
  msg`Patienttransport`,
  msg`Rehab bedömning`,
  msg`Rekrytering av hjälpmedel`,
  msg`Riskbedömning`,
  msg`Råd/regim`,
  msg`Samtal anhörig`,
  msg`Subkutan injektion`,
  msg`SVP - subkutan venport`,
  msg`Sätta på/ta av stödstrumpor`,
  msg`Sårodling`,
  msg`Såromläggning`,
  msg`Träning`,
  msg`Träningsbedömning`,
  msg`Träningsprogram`,
  msg`Urintappning`,
  msg`Urinodling`,
  msg`Utprovning DVT strumpa`,
  msg`Utskrivning`,
  msg`Vätske- och urinmätning`,
  msg`Återlämning nyckel`,
  msg`Ätande och drickande`,
  msg`Överlämning behandlingsmeddelande`,
  msg`Överlämning läkemedelslista`,
] as const;

const videoCallSuggestions = [
  msg`Anhörigsamtal`,
  msg`Andningsbedömning`,
  msg`Andningsgymnastik`,
  msg`Aktivitetsregim`,
  msg`Funktionsbedömning`,
  msg`Förskrvining av hjälpmedel`,
  msg`Förflyttningsbedömmning`,
  msg`Hjälpmedelsbedömning`,
  msg`Hjälpmedelsöversyn`,
  msg`Läkemedelsgenomgång`,
  msg`Patientundervisning`,
  msg`Pumpkontroll digital`,
  msg`Rehab bedömning`,
  msg`Rond`,
  msg`Råd/regim`,
  msg`Samtal anhörig`,
  msg`Träning`,
  msg`Träningsbedömning`,
  msg`Träningsprogram`,
  msg`Uppföljande samtal`,
  msg`Övrig observation`,
  msg`Översyn hemsituation`,
] as const;

const patientTaskSuggestions = [
  msg`Självskattning symptom`,
  msg`Ta medicin`,
  msg`Utvärdering`,
  msg`Vätske- och urinmätning`,
] as const;

const adminTaskSuggestions = [
  msg`Anhörigsamtal`,
  msg`Annan vårdgivare`,
  msg`Behandling på sjukhus`,
  msg`Bedömning vårdavd`,
  msg`Besiktning`,
  msg`Biltvätt`,
  msg`Blanda medicin`,
  msg`Dagsplanering`,
  msg`Diagnostik på sjukhus`,
  msg`Dosettförberedelse`,
  msg`Däckbyte`,
  msg`Fyll på materiallåda`,
  msg`Hårdvara`,
  msg`Information till patient`,
  msg`Inskrivning`,
  msg`Kontakt annan vårdgivare`,
  msg`Kontrollera nya flaggningar`,
  msg`Kontrollräkning av narkotika`,
  msg`Lifecare`,
  msg`Meddela provsvar`,
  msg`Mjukvara`,
  msg`Möte`,
  msg`Norrsken`,
  msg`Onsdagsmöte`,
  msg`Parkering`,
  msg`Påminnelse medicin`,
  msg`Påminnesle möte/rond`,
  msg`Påminnelse vitalparametrar`,
  msg`Patientundervisning`,
  msg`Remiss hemrehab`,
  msg`Tanka`,
  msg`Uppkoppling`,
  msg`Uppföljande samtal`,
  msg`Veckoavstämning`,
  msg`Vårdmöte`,
] as const;

const patientMeasurementTaskSuggestions = [
  msg`Egen mätning`,
  msg`Egen mätning morgon`,
  msg`Egen mätning kväll`,
  msg`Egen mätning andningsfrekvens`,
  msg`Egen mätning blodsocker`,
  msg`Egen mätning puls`,
  msg`Egen mätning syremättnad`,
  msg`Egen mätning temperatur`,
  msg`Egen mätning vikt`,
] as const;

export const titleSuggestionSchema = [
  ...homeVisitSuggestions,
  ...videoCallSuggestions,
  ...patientTaskSuggestions,
  ...adminTaskSuggestions,
  ...patientMeasurementTaskSuggestions,
];

export const titleSuggestionCategoriesSchema = z.enum([
  "HomeVisit",
  "VideoCall",
  "PatientTask",
  "AdminTask",
  "PatientMeasurementTask",
  "Templates",
]);
export const titleSuggestions: Record<
  z.infer<typeof titleSuggestionCategoriesSchema>,
  MessageDescriptor[]
> = {
  Templates: [...templateSuggestions],
  HomeVisit: [...homeVisitSuggestions],
  VideoCall: [...videoCallSuggestions],
  PatientTask: [...patientTaskSuggestions],
  AdminTask: [...adminTaskSuggestions],
  PatientMeasurementTask: [...patientMeasurementTaskSuggestions],
};
