import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { patientKeys, updateWard, useWards } from "@/api/Patients";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { FilledButton } from "@components/Button/Button";
import Form from "@/components/Form/Form";
import { Loading } from "@components/Loading/Loading";
import { deducedError } from "@/Utils/ErrorUtils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import RadioButtons from "@/components/RadioButton/RadioButtons";
import RadioButton from "@/components/RadioButton/RadioButton";
import type { IWard } from "@models/patients";

export const EditWard = ({
  currentWard,
  onSuccess,
  patientId,
}: {
  currentWard: IWard;
  onSuccess: () => void;
  patientId: string;
}) => {
  const currentWardIdAsString = currentWard.id.toString();
  const {
    formState: { errors, isDirty },
    getValues,
    handleSubmit,
    register,
    reset,
    setError,
  } = useForm({
    defaultValues: {
      wardId: currentWardIdAsString,
    },
  });
  const queryClient = useQueryClient();
  const {
    data: wards,
    isPending: isPendingWards,
    isError: isErrorWards,
    error: wardsError,
  } = useWards();
  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: (ward: number) => updateWard(patientId, ward),
    onError: (error) => {
      setError("root.server", {
        message: deducedError(error),
      });
      // Reset `isDirty` to support only showing server error when the form is not changed.
      reset(getValues(), {
        keepErrors: true,
        keepIsSubmitted: true,
        keepTouched: true,
        keepIsValid: true,
        keepSubmitCount: true,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: patientKeys.detail(patientId),
      });
      onSuccess();
    },
  });

  return (
    <Form
      onSubmit={handleSubmit((formData) => {
        mutate(Number(formData.wardId));
      })}
    >
      {isPending || isSuccess ? (
        <Loading message={t`Ändrar avdelning`} />
      ) : (
        <>
          {errors.root?.server?.message && !isDirty ? (
            <ErrorMessage message={errors.root.server.message} />
          ) : undefined}
          <Form.Row>
            {wards ? (
              <RadioButtons
                errorMessage={errors.wardId?.message}
                orientation="horizontal"
              >
                {wards.map((ward) => (
                  <RadioButton
                    label={{ text: ward.displayName }}
                    visualStyle="framed"
                    value={ward.id.toString()}
                    key={ward.id}
                    {...register(`wardId`, {
                      required: {
                        value: true,
                        message: t`Patienten behöver en avdelning`,
                      },
                    })}
                  />
                ))}
              </RadioButtons>
            ) : isPendingWards ? (
              <Loading message={t`Hämtar avdelningar`} />
            ) : isErrorWards || deducedError(wardsError) ? (
              <ErrorMessage message={t`Kunde inte hämta avdelningar`} />
            ) : (
              <></>
            )}
          </Form.Row>
          {isDirty ? (
            <FilledButton type="submit">
              <Trans>Spara ändringar</Trans>
            </FilledButton>
          ) : (
            <></>
          )}
        </>
      )}
    </Form>
  );
};
