import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { useHomeVisitGroup } from "@/api/Activities";
import { deducedError } from "@/Utils/ErrorUtils";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { Loading } from "@components/Loading/Loading";
import { useNavigate, useParams } from "react-router";
import z from "zod";
import styles from "./GroupDetails.module.scss";
import { Heading } from "@components/Heading/Heading";
import { IconButton, PlainButton } from "@components/Button/Button";
import CrossIcon from "@components/icons/CrossIcon";
import { formattedTimeSpan } from "@/components/Time/timeUtils";
import { ActivityTitle } from "@/components/ActivityTitle/ActivityTitle";
import { Text } from "@components/Text/Text";
import { dateName } from "@/Utils/DateUtils";
import { ChangeGroup, CompetencesAndAssignees } from "@/forms/ChangeGroup";
import { useState } from "react";
import clsx from "clsx";
import type { IHomeVisitActivityOccurrence } from "@models/activities";
import { timeOfDayDictionary, timeOfDaySchema } from "@models/activities";
import { TimeSpan } from "@/components/Time/TimeSpan";
import { getUnfulfilledRequirements } from "@/pages/commandcenter/Activities/WorkBlocks/helpers";
import { useLingui } from "@lingui/react";
import { getPatientNameWithStatus } from "@/api/Patients";

export const GroupDetails = ({
  showPatientName = false,
  showCloseButton = true,
}: {
  showPatientName?: boolean;
  showCloseButton?: boolean;
}) => {
  const { _ } = useLingui();
  const { groupId } = z.object({ groupId: z.string() }).parse(useParams());
  const { data: group, isPending, isError, error } = useHomeVisitGroup(groupId);
  const navigate = useNavigate();

  const [state, setState] = useState<
    | { mode: "view" }
    | { mode: "change-group"; occurrence: IHomeVisitActivityOccurrence }
  >({ mode: "view" });

  if (isPending) {
    return <Loading message={t`Hämtar besök`} />;
  }

  if (isError) {
    return (
      <ErrorMessage
        message={`${t`Kunde inte hämta besöket.`} ${deducedError(error)}`}
      />
    );
  }

  const { patient, occurrences, start, end } = group;

  return (
    <article>
      <div className={styles.topRow}>
        <div className={styles.highlightedInfo}>
          {showPatientName ? (
            <Heading level="h2" size="h1" className={styles.patientName}>
              {getPatientNameWithStatus(patient)}
            </Heading>
          ) : null}
          <Heading level="h2">
            <Trans>Hembesök</Trans>
          </Heading>
          <Text element="span" size="large">
            {dateName(start)},{" "}
            {group.timeOfDay === timeOfDaySchema.Values.Any
              ? _(timeOfDayDictionary.Any.long)
              : formattedTimeSpan(start, end)}
          </Text>
        </div>
        {showCloseButton ? (
          <IconButton
            aria-label={t`Stäng detaljvyn`}
            onClick={() => navigate("..")}
          >
            <CrossIcon />
          </IconButton>
        ) : null}
      </div>
      <ul className={styles.activities}>
        {occurrences.map((occurrence) => {
          const { start, end, timeOfDay, id, activityId } = occurrence;

          const occurrenceUnderChange =
            state.mode === "change-group" &&
            state.occurrence.id === id &&
            state.occurrence.activityId === activityId;

          return (
            <li
              key={`${activityId}${id}`}
              className={clsx(
                styles.activityOccurrence,
                occurrenceUnderChange && styles.occurrenceUnderChange,
              )}
            >
              <TimeSpan start={start} end={end} timeOfDay={timeOfDay} />
              <ActivityTitle
                activityOccurrence={occurrence}
                weight="regular"
                size="small"
              />
              <CompetencesAndAssignees
                assignees={occurrence.assignees}
                unfulfilledRequirements={getUnfulfilledRequirements({
                  assignees: occurrence.assignees,
                  occurrences: [occurrence],
                })}
              />
              {occurrenceUnderChange ? (
                <>
                  <PlainButton
                    size="small"
                    weight="light"
                    onClick={() => setState({ mode: "view" })}
                  >
                    <Trans>Avbryt</Trans>
                  </PlainButton>
                  <ChangeGroup
                    activityOccurrence={{
                      ...state.occurrence,
                      aloneInGroup: occurrences.length === 1,
                    }}
                    onSubmitSuccess={() => {
                      return occurrences.length === 1
                        ? navigate("..")
                        : setState({ mode: "view" });
                    }}
                  />
                </>
              ) : (
                <PlainButton
                  size="small"
                  weight="light"
                  onClick={() => setState({ mode: "change-group", occurrence })}
                >
                  <Trans>Flytta</Trans>
                </PlainButton>
              )}
            </li>
          );
        })}
      </ul>
    </article>
  );
};
