import { t } from "@lingui/core/macro";
import { i18n } from "@lingui/core";
import { isYesterday, isTomorrow, isToday } from "date-fns";

const dateAndMonthFormatOptions = {
  month: "long" as const,
  day: "numeric" as const,
};
const dayDateAndMonthFormatOptions = {
  weekday: "long" as const,
  month: "long" as const,
  day: "numeric" as const,
};

export const dateName = (date: Date) => {
  const dateAndMonth = i18n.date(date, dateAndMonthFormatOptions);
  if (isToday(date)) {
    return `${t`Idag`}, ${dateAndMonth}`;
  }

  if (isTomorrow(date)) {
    return `${t`Imorgon`}, ${dateAndMonth}`;
  }

  if (isYesterday(date)) {
    return `${t`Igår`}, ${dateAndMonth}`;
  }

  const dayDateAndMonth = i18n.date(date, dayDateAndMonthFormatOptions);
  return dayDateAndMonth.charAt(0).toUpperCase() + dayDateAndMonth.slice(1);
};
