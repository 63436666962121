import { t } from "@lingui/core/macro";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { Navigate, useLocation } from "react-router";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";

const RequireAuth = ({
  children,
  acceptedRoles,
}: {
  children: React.ReactNode;
  acceptedRoles?: string[];
}) => {
  const location = useLocation();
  const { accounts } = useMsal();
  const roles = accounts[0]?.idTokenClaims?.roles;
  const isAuthenticated = useIsAuthenticated();
  const hasRoleRequirement = Boolean(acceptedRoles);

  const isAllowed =
    isAuthenticated &&
    (hasRoleRequirement
      ? roles?.some((role) => (acceptedRoles as string[]).includes(role))
      : true);

  if (!isAllowed) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    if (isAuthenticated) {
      return (
        <ErrorMessage message={t`Du har inte rätt att besöka den här sidan`} />
      );
    }
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default RequireAuth;
