import { SelectedDateContext } from "@/Utils/useSelectedDate";
import { useState } from "react";

export const SelectedDateContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [selectedDate, setSelectedDate] = useState<string | undefined>(
    undefined,
  );

  return (
    <SelectedDateContext.Provider value={{ selectedDate, setSelectedDate }}>
      {children}
    </SelectedDateContext.Provider>
  );
};
