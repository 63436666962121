import { Trans } from "@lingui/react/macro";
import type { IChip } from "./Chip";
import Chip from "./Chip";

type IPatientChip = Pick<IChip, "size">;

export const PatientChip = ({ ...props }: IPatientChip) => {
  return (
    <Chip color="purple" {...props}>
      <Trans>Patient</Trans>
    </Chip>
  );
};
