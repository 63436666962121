import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { OutlinedButton } from "@components/Button/Button";
import { useParams } from "react-router";
import { z } from "zod";
import styles from "./Communication.module.scss";
import ChatComponent from "./ChatComponent";
import { Heading } from "@components/Heading/Heading";
import {
  notifyBySMS,
  SMS_NOTIFICATION_MESSAGE_FROM_CHAT,
  usePatient,
} from "@/api/Patients";
import { useMutation } from "@tanstack/react-query";
import { deducedError, displayErrorMessageAlert } from "@/Utils/ErrorUtils";
import type { IExistingPatient, IPatient } from "@models/patients";
import { activeStatuses, patientStatusDictionary } from "@models/patients";
import { Text } from "@components/Text/Text";
import { knownFeatureFlagsSchema, useFeatureFlag } from "@/api/FeatureFlags";
import { useLingui } from "@lingui/react";

const hasPhoneNumber = ({
  phoneNumber,
}: Pick<IExistingPatient, "phoneNumber">) => Boolean(phoneNumber);
const isProspectOrAdmitted = ({ status }: Pick<IPatient, "status">) =>
  activeStatuses.some((s) => s === status);
const canSendSms = ({
  phoneNumber,
  status,
}: Pick<IExistingPatient, "phoneNumber" | "status">) =>
  hasPhoneNumber({ phoneNumber }) && isProspectOrAdmitted({ status });

const smsButtonExplanationId = "sms-button-explanation";
const SmsButtonExplanation = ({
  patient,
}: {
  patient: Pick<IExistingPatient, "name" | "phoneNumber" | "status">;
}) => {
  const { _ } = useLingui();

  if (!hasPhoneNumber(patient)) {
    return (
      <Text element="p" id={smsButtonExplanationId}>
        <Trans>
          Det går inte att skicka SMS eftersom patienten inte har något
          telefonnummer registrerat
        </Trans>
      </Text>
    );
  }

  if (!isProspectOrAdmitted(patient)) {
    const prospects = _(
      patientStatusDictionary["prospect"].plural,
    ).toLocaleLowerCase();
    const admitted = _(
      patientStatusDictionary["admitted"].plural,
    ).toLocaleLowerCase();
    return (
      <Text element="p" id={smsButtonExplanationId}>
        <Trans>
          SMS kan endast skickas till patienter som är {prospects} eller{" "}
          {admitted}
        </Trans>
      </Text>
    );
  }

  const patientName = patient.name ?? "patienten";
  return (
    <Text element="p" id={smsButtonExplanationId}>
      <Trans>
        Vid behov, skicka ett SMS till {patientName}. Meddelandet uppmanar
        mottagaren att öppna och läsa chatten.
      </Trans>
    </Text>
  );
};

export const Communication = () => {
  const { _ } = useLingui();
  const { patientId } = z.object({ patientId: z.string() }).parse(useParams());
  const { data: patient } = usePatient(patientId);

  const { data: newVideoSolution } = useFeatureFlag(
    knownFeatureFlagsSchema.Values.NewVideoSolution,
  );
  const { data: acsVideoEnabled } = useFeatureFlag(
    knownFeatureFlagsSchema.Values.ACSVideo,
  );

  const { mutate: sendChatNotificationSMS, isPending: isSendingSMS } =
    useMutation({
      mutationFn: () => {
        return notifyBySMS(patientId);
      },
      onError: (error) => {
        displayErrorMessageAlert(
          `${t`Gick inte att skicka SMS.`} ${deducedError(error)}`,
        );
      },
    });

  if (!patient) {
    return null;
  }
  const patientName = patient.name;
  const patientPhoneNumber = patient.phoneNumber;

  const handleSendChatNotificationSMSClick = () => {
    // TODO: Remove when Lingui 5 drops: https://github.com/lingui/js-lingui/issues/370#issuecomment-2172480857
    const TWO_LINE_BREAKS = "\n\n";
    const localizedTextMessage = _(SMS_NOTIFICATION_MESSAGE_FROM_CHAT);
    if (
      window.confirm(
        t`Följande SMS skickas till ${patientName} (${
          patientPhoneNumber
        }):${TWO_LINE_BREAKS}"${localizedTextMessage}"${TWO_LINE_BREAKS}Vill du skicka det?`,
      )
    ) {
      sendChatNotificationSMS();
    } else return;
  };

  return (
    <div className={styles.container}>
      <div className={styles.chatContainer}>
        <ChatComponent />
      </div>
      <section className={styles.communicationTools}>
        <article>
          <Heading level="h2">
            <Trans>SMS-notis</Trans>
          </Heading>
          <SmsButtonExplanation patient={patient} />
          <OutlinedButton
            aria-describedby={smsButtonExplanationId}
            disabled={isSendingSMS || !canSendSms(patient)}
            onClick={handleSendChatNotificationSMSClick}
          >
            <Trans>Skicka SMS</Trans>
          </OutlinedButton>
        </article>
        <article>
          <Heading level="h2">
            <Trans>Videosamtal</Trans>
          </Heading>
          <Text element="p" id="video-meeting-explanation">
            <Trans>
              Videosamtalet öppnas i en ny flik i din webbläsare. Du kan växla
              mellan webbläsarens flikar som du vill.
            </Trans>
          </Text>
          <OutlinedButton
            aria-describedby="video-meeting-explanation"
            onClick={() =>
              window.open(`/video/${patientId}?video-service=jitsi`, "_blank")
            }
          >
            {newVideoSolution || acsVideoEnabled ? (
              <Trans>Starta videosamtal (Jitsi)</Trans>
            ) : (
              <Trans>Starta videosamtal</Trans>
            )}
          </OutlinedButton>
          {newVideoSolution ? (
            <OutlinedButton
              aria-describedby="video-meeting-explanation"
              onClick={() =>
                window.open(
                  `/video/${patientId}?video-service=compodium`,
                  "_blank",
                )
              }
            >
              <Trans>Starta videosamtal (Compodium)</Trans>
            </OutlinedButton>
          ) : null}
          {acsVideoEnabled ? (
            <OutlinedButton
              aria-describedby="video-meeting-explanation"
              onClick={() =>
                window.open(`/video/${patientId}?video-service=acs`, "_blank")
              }
            >
              <Trans>Starta videosamtal (ACS)</Trans>
            </OutlinedButton>
          ) : null}
        </article>
      </section>
    </div>
  );
};
