import { t } from "@lingui/core/macro";
import type {
  IActivityCategory,
  IActivityOccurrence,
  IActivityOccurrenceStatus,
  IHomeVisitActivityOccurrenceWithoutPatientAndAssignees,
} from "@models/activities";
import {
  activityOccurrenceStatusSchema,
  categorySchema,
  getActivityOccurrenceStatusTimestamp,
  timeOfDayDictionary,
  visitStatusSchema,
} from "@models/activities";
import { DetailsPopover } from "../DetailsPopover/DetailsPopover";
import styles from "./TimelineTile.module.scss";
import clsx from "clsx";
import { ActivityDetails, Details } from "./Details";
import type { IVisit, IVisitStatus } from "@models/visits";
import { calculateVisitTimes, getVisitStatusTimestamp } from "@models/visits";
import { ActivityGroupTag } from "./ActivityGroupTag/ActivityGroupTag";
import { getInitials } from "@components/CircledInitials/initialsUtils";
import type { IStatusTag } from "@/components/StatusTag/StatusTag";
import { StatusTag } from "@/components/StatusTag/StatusTag";
import NotificationCircle from "@components/NotificationCircle/NotificationCircle";
import { ActivityTitle } from "@/components/ActivityTitle/ActivityTitle";
import { useLocation } from "react-router";
import type { IStatus } from "@/components/StatusTag/statusTagUtils";
import { formattedTimeSpan } from "@/components/Time/timeUtils";
import type { IAllocatableItemType } from "../Shifts";
import { DragHandle } from "@/components/DragAndDrop/DragHandle";
import { useRef } from "react";
import { useLingui } from "@lingui/react";
import { patientStatusDictionary, patientStatusSchema } from "@models/patients";

type IVariant = "default" | "compact";

type ITile = {
  category: IActivityCategory;
  variant: IVariant;
  status: IStatus;
  statusTimestamp?: Date;
  patientName?: string;
  activityCount?: number;
  isAnyTimeOfDay?: boolean;
  timespan?: { start: Date; end: Date };
  showIndicator?: boolean;
  activities?:
    | IActivityOccurrence[]
    | IHomeVisitActivityOccurrenceWithoutPatientAndAssignees[];
};

const Tile = ({
  category,
  variant,
  status,
  statusTimestamp,
  patientName,
  activityCount,
  isAnyTimeOfDay,
  timespan,
  showIndicator,
  activities,
}: ITile) => {
  const { _ } = useLingui();
  const DisplayedStatusAndTime = ({ size }: Pick<IStatusTag, "size">) => {
    const displayTime = isAnyTimeOfDay
      ? _(timeOfDayDictionary.Any.short)
      : timespan
        ? formattedTimeSpan(timespan.start, timespan.end)
        : "-";

    const statusesWithoutTimestamp = [
      activityOccurrenceStatusSchema.Values.notReady,
      activityOccurrenceStatusSchema.Values.ready,
      visitStatusSchema.Values.planned,
    ];
    const displayStatusWithTimestamp =
      statusTimestamp &&
      !statusesWithoutTimestamp.find((item) => item === status);

    return (
      <div className={styles.statusAndTime}>
        {displayStatusWithTimestamp ? (
          <StatusTag
            status={status}
            timestamp={statusTimestamp}
            variant="icon-with-timestamp"
            size={size}
          />
        ) : (
          <div className={styles.time}>{displayTime}</div>
        )}
        {showIndicator && <NotificationCircle isVisible />}
      </div>
    );
  };

  const queryParams = useLocation().search;

  if (variant === "compact") {
    return (
      <div className={clsx(styles.innerTileContainer, styles[variant])}>
        <ActivityGroupTag
          category={category}
          title={patientName ? getInitials(patientName) : undefined}
          count={activityCount}
        />
        <DisplayedStatusAndTime size="tiny" />
      </div>
    );
  }

  return (
    <div className={clsx(styles.innerTileContainer, styles[variant])}>
      <ActivityGroupTag
        category={category}
        title={patientName ? patientName : undefined}
      />
      {activities && activities.length > 0 ? (
        <ul>
          {activities.map((activity) => (
            <li key={activity.id}>
              <ActivityTitle
                activityOccurrence={activity}
                linkTo={`activities/${activity.activityId}/occurrences/${activity.id}${queryParams}`}
                weight="regular"
                showStatus={activity.status === "expired"}
              />
            </li>
          ))}
        </ul>
      ) : null}
      <DisplayedStatusAndTime size="small" />
    </div>
  );
};

const tileClassName = (
  status: IActivityOccurrenceStatus | IVisitStatus,
  variant: IVariant,
  itemType: Exclude<IAllocatableItemType, undefined>,
) => {
  return clsx(
    styles.timeLineTile,
    styles[`variant-${variant}`],
    status === activityOccurrenceStatusSchema.Values.finished && styles.faded,
    styles[itemType],
  );
};

export const TimelineVisitTile = ({
  visit,
  variant,
  routeId,
  isExamining,
  stopExamining,
  isAloneInRoute,
}: {
  visit: IVisit;
  variant: IVariant;
  routeId: string;
  isExamining: boolean;
  stopExamining: (isOpen: boolean) => void;
  isAloneInRoute: boolean;
}) => {
  const { _ } = useLingui();
  const { status, occurrences, patient } = visit;
  const statusTimestamp = getVisitStatusTimestamp(visit);
  const { start, end, isAnyTimeOfDay } = calculateVisitTimes(visit);
  const visitHasWellDefinedTime = start && end;
  const visitHasExpiredActivities = occurrences.some(
    (activity) => activity.status === "expired",
  );
  const triggerRef = useRef(null);
  const patientName =
    patient.status === patientStatusSchema.Values.deleted
      ? _(patientStatusDictionary.deleted.singular)
      : patient.name;

  return (
    <>
      <article
        ref={triggerRef}
        className={tileClassName(status, variant, "group")}
      >
        {!isAloneInRoute && <DragHandle />}
        <Tile
          category={categorySchema.Values.HomeVisit}
          variant={variant}
          status={status}
          statusTimestamp={statusTimestamp}
          patientName={patientName}
          activityCount={occurrences.length}
          isAnyTimeOfDay={isAnyTimeOfDay}
          timespan={visitHasWellDefinedTime ? { start, end } : undefined}
          showIndicator={visitHasExpiredActivities}
          activities={occurrences}
        />
      </article>
      <DetailsPopover
        dialogProps={{ "aria-label": t`Hembesök för ${patientName}` }}
        popoverProps={{
          placement: "start",
          triggerRef: triggerRef,
          isOpen: isExamining,
          onOpenChange: stopExamining,
        }}
      >
        <Details visit={visit} routeId={routeId} />
      </DetailsPopover>
    </>
  );
};

export const TimelineActivityTile = ({
  activityOccurrence,
  variant,
  isExamining,
  stopExamining,
}: {
  activityOccurrence: IActivityOccurrence;
  variant: IVariant;
  isExamining: boolean;
  stopExamining: (isOpen: boolean) => void;
}) => {
  const { _ } = useLingui();
  const triggerRef = useRef(null);

  const { category, start, end, status, patient, timeOfDay } =
    activityOccurrence;
  const isAnyTimeOfDay = timeOfDay === "Any";
  const statusTimestamp =
    getActivityOccurrenceStatusTimestamp(activityOccurrence);
  const patientName = patient
    ? patient.status === patientStatusSchema.Values.deleted
      ? _(patientStatusDictionary.deleted.singular)
      : patient.name
    : undefined;

  return (
    <>
      <article
        ref={triggerRef}
        className={tileClassName(status, variant, "activityOccurrence")}
      >
        <Tile
          category={category}
          variant={variant}
          status={status}
          statusTimestamp={statusTimestamp}
          patientName={patientName}
          isAnyTimeOfDay={isAnyTimeOfDay}
          timespan={{ start, end }}
          activities={[activityOccurrence]}
        />
      </article>
      <DetailsPopover
        dialogProps={{ "aria-label": t`Öppna aktivitet för ${patientName}` }}
        popoverProps={{
          placement: "start",
          triggerRef: triggerRef,
          isOpen: isExamining,
          onOpenChange: stopExamining,
        }}
      >
        <ActivityDetails activityOccurrence={activityOccurrence} />
      </DetailsPopover>
    </>
  );
};
