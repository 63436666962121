import styles from "./Tabs.module.scss";

type ITabs = {
  children: React.ReactNode;
};

const Tabs = ({ children }: ITabs) => {
  return (
    <div className={styles.tabs}>
      <>{children}</>
    </div>
  );
};

export default Tabs;
