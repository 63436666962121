import type { MatrixClient } from "matrix-js-sdk";
import { createContext } from "react";

const ChatContext = createContext<{
  matrixClient: MatrixClient | null;
  unreadNotifications?: Record<string, number>;
  chatEnabled: boolean;
}>({
  matrixClient: null,
  chatEnabled: false,
});
export default ChatContext;
