import { useEffect, useSyncExternalStore } from "react";
import { sendReadReceipt } from "./matrixClient";
import type { MatrixClient, MatrixEvent } from "matrix-js-sdk";

// Essential for desktop devices since it detects if the user is currently active on the page
const useChatIsActive = () => {
  const subscribe = (callback: () => void) => {
    window.addEventListener("focus", callback);
    window.addEventListener("blur", callback);
    return () => {
      window.removeEventListener("focus", callback);
      window.removeEventListener("blur", callback);
    };
  };

  const chatIsActive = useSyncExternalStore(subscribe, () =>
    document.hasFocus(),
  );
  return chatIsActive;
};

// Essential for tablet and mobile devices since it detects screen lock and navigation to other apps
const useChatIsVisible = () => {
  const subscribe = (callback: () => void) => {
    window.addEventListener("visibilitychange", callback);
    return () => {
      window.removeEventListener("visibilitychange", callback);
    };
  };

  const chatIsVisible = useSyncExternalStore(
    subscribe,
    () => document.visibilityState === "visible",
  );
  return chatIsVisible;
};

export const useSendReadReceipt = (
  matrixClient: MatrixClient | null,
  timelineEvents: MatrixEvent[],
  userId: string | null | undefined,
) => {
  const eventsFromOtherUsers = timelineEvents.filter(
    (event) => event.getSender() !== userId,
  );

  const eventToMarkAsRead = eventsFromOtherUsers.at(-1) ?? null;

  const chatIsActive = useChatIsActive();
  const chatIsVisible = useChatIsVisible();

  useEffect(() => {
    if (!matrixClient || !chatIsActive || !chatIsVisible) return;
    sendReadReceipt(matrixClient, eventToMarkAsRead);
  }, [matrixClient, eventToMarkAsRead, chatIsActive, chatIsVisible]);
};
