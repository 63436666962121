import clsx from "clsx";
import styles from "./Tag.module.scss";

export type ITag = {
  icon: React.ReactNode;
  label: string;
  color: "gray" | "blue" | "yellow" | "green" | "red";
  localizedTimestamp?: string;
  variant:
    | "icon"
    | "icon-with-timestamp"
    | "icon-and-text"
    | "icon-and-text-with-timestamp";
  size: "tiny" | "small" | "medium";
};
export const Tag = ({
  icon,
  label,
  color,
  localizedTimestamp,
  variant,
  size,
}: ITag) => {
  if (variant === "icon") {
    return (
      <div
        className={clsx(
          styles.tag,
          styles[`variant-${variant}`],
          styles[`color-${color}`],
          styles[`size-${size}`],
        )}
      >
        {icon}
      </div>
    );
  }

  if (variant === "icon-with-timestamp") {
    return (
      <div
        className={clsx(
          styles.tag,
          styles[`variant-${variant}`],
          styles[`color-${color}`],
          styles[`size-${size}`],
        )}
      >
        {icon}
        {localizedTimestamp ? (
          <div className={styles.text}>{localizedTimestamp}</div>
        ) : null}
      </div>
    );
  }

  return (
    <div
      className={clsx(
        styles.tag,
        styles[`variant-${variant}`],
        styles[`color-${color}`],
        styles[`size-${size}`],
      )}
    >
      {icon}
      <div className={styles.text}>
        <span>{label}</span>
        {localizedTimestamp ? <span>{localizedTimestamp}</span> : null}
      </div>
    </div>
  );
};
