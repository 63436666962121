import { msg } from "@lingui/core/macro";
import { useFormContext } from "react-hook-form";
import RadioButton from "@/components/RadioButton/RadioButton";
import RadioButtons from "@/components/RadioButton/RadioButtons";
import type { ICreatePatientNoteFormData } from "./CreatePatientNoteForm";
import { patientNoteTypes, typeDictionary } from "@models/patientNotes";
import { useLingui } from "@lingui/react";

export const PatientNoteTypePicker = () => {
  const { register } = useFormContext<ICreatePatientNoteFormData>();
  const { _ } = useLingui();

  return (
    <RadioButtons orientation="horizontal" legend={_(msg`Anteckningstyp`)}>
      {patientNoteTypes.map((type) => (
        <RadioButton
          key={type}
          label={{ text: _(typeDictionary[type]) }}
          visualStyle="framed"
          value={type}
          {...register("anonymisedNote.type")}
        />
      ))}
    </RadioButtons>
  );
};
