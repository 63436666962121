import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  activityOccurrenceAndGroupKeys,
  allocateShiftsToVisit,
  deallocateShiftsFromVisit,
} from "@/api/Activities";
import ArrowUpIcon from "@components/icons/SVG/arrowUp.svg?react";
import ArrowDownIcon from "@components/icons/SVG/arrowDown.svg?react";
import styles from "./Visit.module.scss";
import { RequirementChip } from "@/components/Chips/RequirementChip";
import { deducedError, displayErrorMessageAlert } from "@/Utils/ErrorUtils";
import { IconButton, PlainButton } from "@components/Button/Button";
import { Heading } from "@components/Heading/Heading";
import { getUnfulfilledRequirements } from "./helpers";
import { ActivityOccurrence } from "./ActivityOccurrence";
import type { IVisit } from "@models/visits";
import { AssignedShiftChip } from "@/components/Chips/AssignedShiftChip";
import { ShiftDropdownMenu } from "../ShiftDropdownMenu";
import { getPatientNameWithStatus } from "@/api/Patients";
import { routeKeys, unassignGroupFromRoute, visitKeys } from "@/api/Routes";
import { QuickActivityPopover } from "@/components/QuickActivityPopover/QuickActivityPopover";
import { knownFeatureFlagsSchema, useFeatureFlag } from "@/api/FeatureFlags";
import CrossIcon from "@components/icons/CrossIcon";
import { QuickActivityPopoverWithRoutesAndVisits } from "@/components/QuickActivityPopover/QuickActivityPopoverWithRoutesAndVisits";
import { startOfDay, startOfToday } from "date-fns";
import { DateOfBirth } from "./DateOfBirth";

export const Visit = ({
  isUpdatingOrder,
  moveUp,
  moveDown,
  position,
  routeId,
  visit,
  isEditable = false,
}: {
  isUpdatingOrder: boolean;
  moveUp(): void;
  moveDown(): void;
  position: "only" | "first" | "last" | "other";
  routeId: string;
  visit: IVisit;
  isEditable?: boolean;
}) => {
  const { data: quickActivities } = useFeatureFlag(
    knownFeatureFlagsSchema.Values.QuickActivities,
  );
  const { data: disableRouteAllocationInOldViews } = useFeatureFlag(
    knownFeatureFlagsSchema.Values.DisableRouteAllocationInOldViews,
  );

  const queryClient = useQueryClient();
  const { mutate: allocateShiftsMutation, isPending: isAllocating } =
    useMutation({
      mutationFn: ({ shiftIds }: { shiftIds: number[] }) =>
        allocateShiftsToVisit(shiftIds, routeId, visit.id),
      onError: (error) => {
        displayErrorMessageAlert(
          `${t`Gick inte att lägga till arbetspasset på besöket.`} ${deducedError(
            error,
          )}`,
        );
      },
      onSuccess: () => {
        return Promise.all([
          queryClient.invalidateQueries({
            queryKey: activityOccurrenceAndGroupKeys.all(),
          }),
          queryClient.invalidateQueries({
            queryKey: routeKeys.lists(),
          }),
          queryClient.invalidateQueries({
            queryKey: visitKeys(routeId).detail(visit.id),
          }),
        ]);
      },
    });

  const { mutate: deallocateShiftsMutation, isPending: isDeallocating } =
    useMutation({
      mutationFn: ({ shiftIds }: { shiftIds: number[] }) =>
        deallocateShiftsFromVisit(shiftIds, routeId, visit.id),
      onError: (error) => {
        displayErrorMessageAlert(
          `${t`Gick inte att ta bort arbetspasset från besöket.`} ${deducedError(
            error,
          )}`,
        );
      },
      onSuccess: () => {
        return Promise.all([
          queryClient.invalidateQueries({
            queryKey: activityOccurrenceAndGroupKeys.all(),
          }),
          queryClient.invalidateQueries({
            queryKey: routeKeys.lists(),
          }),
          queryClient.invalidateQueries({
            queryKey: visitKeys(routeId).detail(visit.id),
          }),
        ]);
      },
    });

  const {
    mutate: unassignGroupFromRouteMutation,
    isPending: isUnassigningGroupFromRoute,
  } = useMutation({
    mutationFn: ({ groupId }: { groupId: string }) =>
      unassignGroupFromRoute(groupId, routeId),
    onError: (error) => {
      displayErrorMessageAlert(
        `${t`Gick inte att ta bort besöket från rutten.`} ${deducedError(error)}`,
      );
    },
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.all(),
        }),
        queryClient.invalidateQueries({
          queryKey: routeKeys.lists(),
        }),
      ]);
    },
  });

  const appendShift = (shiftIdToAdd: number) => {
    allocateShiftsMutation({
      shiftIds: [shiftIdToAdd],
    });
  };

  const removeShift = (shiftIdToRemove: number) => {
    deallocateShiftsMutation({
      shiftIds: [shiftIdToRemove],
    });
  };

  const { assignees } = visit;
  const assignedShiftIds = assignees.map(({ id }) => id);
  const unfulfilledRequirements = getUnfulfilledRequirements(visit);
  const enableDeallocate = disableRouteAllocationInOldViews
    ? assignees.length > 1
    : true;
  const isDeallocatable = isEditable && enableDeallocate;

  const RearrangeArrows = () => {
    return position !== "only" && isEditable ? (
      <div className={styles.controls}>
        {position !== "first" ? (
          <IconButton
            aria-label={t`Tidigarelägg`}
            size="small"
            disabled={isUpdatingOrder}
            onClick={moveUp}
          >
            <ArrowUpIcon />
          </IconButton>
        ) : (
          <></>
        )}
        {position !== "last" ? (
          <IconButton
            aria-label={t`Senarelägg`}
            size="small"
            disabled={isUpdatingOrder}
            onClick={moveDown}
          >
            <ArrowDownIcon />
          </IconButton>
        ) : (
          <></>
        )}
      </div>
    ) : (
      <></>
    );
  };

  const Assignees = () => {
    return (
      <>
        <div className={styles.competencesAndAssignees}>
          {unfulfilledRequirements.length > 0 ? (
            <ul className={styles.competences}>
              {unfulfilledRequirements.map((unfulfilledRequirement) => (
                <li key={unfulfilledRequirement}>
                  <RequirementChip
                    requirement={unfulfilledRequirement}
                    state="unfulfilled"
                    size="small"
                  />
                </li>
              ))}
            </ul>
          ) : undefined}
          {assignees.length > 0 ? (
            <ul className={styles.assignees}>
              {assignees.map((shift) => (
                <li key={shift.id}>
                  <AssignedShiftChip
                    medicalCompetence={shift.competence}
                    shift={shift}
                    size="small"
                    {...(isDeallocatable && {
                      onRemove: () => removeShift(shift.id),
                    })}
                    disabled={isDeallocating}
                  />
                </li>
              ))}
            </ul>
          ) : undefined}
        </div>
        {isEditable ? (
          <div className={styles.addAssigneeButton}>
            <ShiftDropdownMenu
              assignedShiftIds={assignedShiftIds}
              unfulfilledRequirements={unfulfilledRequirements}
              onAllocate={appendShift}
              isAllocating={isAllocating}
              shiftsFromDate={startOfDay(
                visit.occurrences[0]
                  ? visit.occurrences[0].start
                  : startOfToday(),
              )}
            />
          </div>
        ) : null}
      </>
    );
  };

  const { data: isActivityGroupingEnabled } = useFeatureFlag(
    knownFeatureFlagsSchema.Values.ActivityGrouping,
  );

  return (
    <li className={styles.visit}>
      <div className={styles.topRow}>
        <Heading level="h4" weight="regular">
          {getPatientNameWithStatus(visit.patient)}
        </Heading>
        <Assignees />
        <RearrangeArrows />
      </div>
      <DateOfBirth patient={visit.patient} />
      <ul className={styles.activitiesList} aria-label={t`Aktiviteter`}>
        {visit.occurrences.map((occurrence) => (
          <ActivityOccurrence
            key={`${occurrence.activityId}${occurrence.id}`}
            activityOccurrence={occurrence}
            onlyActivityOccurrenceInVisit={visit.occurrences.length === 1}
            routeId={routeId}
            isEditable={isEditable}
            status={occurrence.status}
          />
        ))}
      </ul>
      <div className={styles.buttons}>
        {quickActivities && isEditable ? (
          isActivityGroupingEnabled ? (
            <QuickActivityPopover groupId={visit.id} />
          ) : (
            <QuickActivityPopoverWithRoutesAndVisits
              visitId={visit.id}
              routeId={routeId}
            />
          )
        ) : null}
        {isActivityGroupingEnabled && isEditable ? (
          <PlainButton
            size="small"
            weight="light"
            onClick={() =>
              unassignGroupFromRouteMutation({ groupId: visit.id })
            }
            disabled={isUnassigningGroupFromRoute}
          >
            <CrossIcon />
            <Trans>Ta bort besök</Trans>
          </PlainButton>
        ) : null}
      </div>
    </li>
  );
};
