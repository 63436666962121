import { msg } from "@lingui/core/macro";
import z from "zod";
import type { MessageDescriptor } from "@lingui/core";

const productCodesExternal = [
  {
    name: msg`Uppdrag blodtransfusion på remiss från hematolog eller onkolog
`,
    code: "29116",
  },
  { name: msg`Uppdrag på SÄBO ska kompletteras med KVÅ`, code: "29114" },
];

const actionCodesAdvancedHomeCare = [
  { name: msg`Manuellt lymfdränage`, code: "DP006" },
  { name: msg`Palliativ vård`, code: "DV097" },
  { name: msg`Brytpunktsamtal`, code: "XV018" },
  { name: msg`Användande av tolk`, code: "ZV020" },
  { name: msg`Åtgärd med palliativ avsikt`, code: "ZV400" },
  { name: msg`Transfusion, erytrocyter, allogen`, code: "DR029" },
  { name: msg`Vård i livets slutskede`, code: "QX006" },
  { name: msg`Trygghetskvitto`, code: "UV017" },
  { name: msg`Konferens med patient`, code: "XS007" },
  { name: msg`Konferens om patient`, code: "XS008" },
  { name: msg`Läkemedelsgenomgång, enkel`, code: "XV015" },
  { name: msg`Läkemedelsgenomgång, fördjupad`, code: "XV016" },
];

const actionCodesExternal = [
  {
    name: msg`Uppdrag blodtransfusion på remiss från husläkare`,
    code: "UW014",
  },
  { name: msg`Läkemedelstillförsel, intravenös`, code: "DT016" },
  { name: msg`Transfusion, helblod, allogen`, code: "XV018" },
  { name: msg`Smärtreducerande behandling`, code: "QB008" },
  {
    name: msg`Nutritionsbehandling, parenteral näringstillförsel`,
    code: "DV055",
  },
  { name: msg`Skötsel av dränage`, code: "DV150" },
  { name: msg`Palliativ vård i livets slutskede`, code: "QX006" },
  {
    name: msg`Brakyterapi, interstitiell lågdosrat (temporärt implantat)`,
    code: "DV014",
  },
  { name: msg`Konferens med patient`, code: "XS007" },
  { name: msg`Konferens om patient`, code: "XS008" },
  { name: msg`Läkemedelsgenomgång, enkel`, code: "XV015" },
  { name: msg`Läkemedelsgenomgång, fördjupad`, code: "XV016" },
  {
    name: msg`Uppdrag konsultinsats/stöd till andra vårdgivare (hälso- och sjukvård)`,
    code: "GB004",
  },
];

//TODO: import from patients.ts and filter out hostpitalAtHome
export const patientTypeForInvoicing = z.enum(["AdvancedHomeCare", "External"]);

export type IPatientTypeForInvoicing = z.infer<typeof patientTypeForInvoicing>;

interface HealthCareProcedureCode {
  name: MessageDescriptor;
  code: string;
}

interface ProductCode {
  name: MessageDescriptor;
  code: string;
}

export const healthCareProcedureCodes: Record<
  IPatientTypeForInvoicing,
  HealthCareProcedureCode[]
> = {
  AdvancedHomeCare: [...actionCodesAdvancedHomeCare],
  External: [...actionCodesExternal],
};

export const productCodes: Record<IPatientTypeForInvoicing, ProductCode[]> = {
  AdvancedHomeCare: [],
  External: [...productCodesExternal],
};
