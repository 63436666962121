import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import clsx from "clsx";
import styles from "./MessageGroup.module.scss";
import CrossIcon from "../icons/CrossIcon";
import { isToday, isYesterday } from "date-fns";
import Portrait from "../Portrait/Portrait";
import { i18n } from "@lingui/core";

// Duplicated from `chat` package until we figure out best sharing pattern.
type IMessageStatus =
  // The event is in the process of being sent.
  | "sending"
  // The event has been sent to the server, and echo is received.
  | "sent"
  // The event was not sent and will no longer be retried.
  | "not-sent";

export type IMessageGroup = {
  children: React.ReactNode;
  placement: "right" | "left";
  senderName?: string;
  senderTitle?: string;
  timestamp: string;
  hideAvatar?: boolean;
  status: IMessageStatus;
  readIndicator?: Date | boolean | undefined;
  senderPhotoURL?: string | null;
  isLatestMessage?: boolean;
};

export const MessageGroup = ({
  children,
  placement,
  senderName,
  senderTitle,
  status,
  timestamp,
  hideAvatar = false,
  readIndicator,
  senderPhotoURL,
  isLatestMessage,
}: IMessageGroup) => {
  const showComplementaryInformation =
    status === "not-sent" || (!hideAvatar && senderName);

  return (
    <div
      className={clsx(
        styles.messageGroup,
        styles[`placement-${placement}`],
        styles[`status-${status}`],
        !showComplementaryInformation && styles.noComplementaryInformation,
      )}
    >
      <div className={styles.senderAndTimestamp}>
        {senderName !== undefined ? (
          <span className={styles.sender}>{senderName}, </span>
        ) : null}
        {senderTitle !== undefined ? <span>{senderTitle}, </span> : null}
        {status === "sent" ? (
          <time>{timestamp}</time>
        ) : status === "sending" ? (
          <i>
            <Trans>Skickar...</Trans>
          </i>
        ) : (
          <i>
            <Trans>Ej levererat</Trans>
          </i>
        )}
      </div>
      {typeof readIndicator === "object" ? (
        <div className={styles.readIndicator}>
          <span>{formatReadMessageTimestamp(readIndicator)}</span>
        </div>
      ) : readIndicator ? (
        <div className={styles.readIndicator}>
          <span>
            <Trans>Läst</Trans>
          </span>
        </div>
      ) : isLatestMessage && status === "sent" ? (
        <div className={styles.deliveredNotRead}>
          <span>
            <Trans>Levererat, ännu ej läst</Trans>
          </span>
        </div>
      ) : null}
      {showComplementaryInformation ? (
        <div className={styles.complementaryInformation}>
          {status === "not-sent" ? (
            <CrossIcon />
          ) : (
            // showComplementaryInformation implies that senderName is defined
            <Portrait name={senderName!} photoUrl={senderPhotoURL} />
          )}
        </div>
      ) : null}
      <div className={styles.messages}>{children}</div>
    </div>
  );
};

const formatReadMessageTimestamp = (timestamp: Date) => {
  const time = i18n.date(timestamp, { hour: "numeric", minute: "2-digit" });
  const dateName = i18n.date(timestamp, { day: "numeric", month: "long" });
  if (isToday(timestamp)) {
    return t`Läst ${time}`;
  } else if (isYesterday(timestamp)) {
    return t`Lästes igår`;
  } else {
    return t`Lästes ${dateName}`;
  }
};
