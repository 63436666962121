import { useState } from "react";
import { RoutesContext } from "./RoutesContext";

export const RoutesContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [selectedRouteId, setSelectedRouteId] = useState<string | undefined>(
    undefined,
  );

  return (
    <RoutesContext.Provider value={{ selectedRouteId, setSelectedRouteId }}>
      {children}
    </RoutesContext.Provider>
  );
};
