import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { LoadingOverlay } from "@components/Loading/Loading";
import styles from "./AllocateToShiftTile.module.scss";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deducedError, displayErrorMessageAlert } from "@/Utils/ErrorUtils";
import { allocateShiftsToActivityOccurrence } from "@/api/Activities";
import { DropZone } from "@/components/DragAndDrop/DropZone";
import PlusIcon from "@components/icons/PlusIcon";
import { assignGroupToShift } from "@/api/Shifts";
import * as Sentry from "@sentry/react";
import { shiftName, type IScheduledShift } from "@models/shifts";
import clsx from "clsx";
import { ShiftsContext } from "../ShiftsContext";
import { useContext } from "react";
import { isGroup } from "@models/activities";

export const AllocateToShiftTile = ({
  shift,
  onPendingAddToShift,
  children,
}: {
  shift: IScheduledShift;
  onPendingAddToShift: (onPending: boolean) => void;
  children: React.ReactNode;
}) => {
  const queryClient = useQueryClient();
  const { draggedItem, setDraggedItem } = useContext(ShiftsContext);
  const { id: shiftId } = shift;
  const nameOfShift = shiftName({ ...shift, options: { length: "long" } });

  const { mutate: placeActivityOccurrenceInBlock, isPending: isAllocating } =
    useMutation({
      mutationFn: ({
        activityId,
        occurrenceId,
      }: {
        activityId: string;
        occurrenceId: string;
      }) =>
        allocateShiftsToActivityOccurrence([shiftId], activityId, occurrenceId),
      onError: (err) => {
        displayErrorMessageAlert(
          `${t`Gick inte att lägga till aktiviteten i arbetspasset.`} ${deducedError(err)}`,
        );
      },
      onMutate: () => {
        onPendingAddToShift(true);
      },
      onSuccess: async () => {
        return queryClient.invalidateQueries();
      },
      onSettled: () => {
        setDraggedItem(undefined);
        onPendingAddToShift(false);
      },
    });

  const { mutate: placeGroupInBlock, isPending: isPlacingGroupInBlock } =
    useMutation({
      mutationFn: ({ groupId }: { groupId: string }) =>
        assignGroupToShift(groupId, shiftId),
      onError: (err) => {
        displayErrorMessageAlert(
          `${t`Gick inte att lägga till besöket i rutten.`} ${deducedError(err)}`,
        );
      },
      onMutate: () => {
        onPendingAddToShift(true);
      },
      onSuccess: async () => {
        return queryClient.invalidateQueries();
      },
      onSettled: () => {
        setDraggedItem(undefined);
        onPendingAddToShift(false);
      },
    });

  const handleGroupOnDrop = (droppedText: string) => {
    placeGroupInBlock({
      groupId: droppedText,
    });
  };

  const handleActivityOccurrenceOnDrop = (droppedText: string) => {
    const [activityId, occurrenceId] = droppedText.split(":");

    if (!activityId || !occurrenceId) {
      Sentry.captureException(
        new Error(
          "A dropped item is missing at least one of the mandatory 'activityId' or 'occurrenceId'.",
        ),
      );

      displayErrorMessageAlert(
        t`Gick inte att lägga till aktiviteten i arbetspasset.`,
      );
      return;
    }

    placeActivityOccurrenceInBlock({
      activityId: activityId,
      occurrenceId: occurrenceId,
    });
  };

  const isAllocatableItemSelected = draggedItem !== undefined;

  if (draggedItem && isGroup(draggedItem)) {
    return (
      <LoadingOverlay show={isPlacingGroupInBlock}>
        <DropZone
          ariaLabel={t`Skapa rutt för ${nameOfShift}`}
          getDroppedText={handleGroupOnDrop}
          className={clsx(
            styles.dropZone,
            isAllocatableItemSelected ? styles.selected : undefined,
          )}
          isDisabled={!isAllocatableItemSelected}
        >
          {children}
          {isAllocatableItemSelected && (
            <article className={styles.allocateToShiftContainer}>
              <PlusIcon />
              <Trans>Ny rutt</Trans>
            </article>
          )}
        </DropZone>
      </LoadingOverlay>
    );
  } else {
    return (
      <LoadingOverlay show={isAllocating}>
        <DropZone
          ariaLabel={t`Lägg till aktivitet för ${nameOfShift}`}
          getDroppedText={handleActivityOccurrenceOnDrop}
          className={clsx(
            styles.dropZone,
            isAllocatableItemSelected ? styles.selected : undefined,
          )}
          isDisabled={!isAllocatableItemSelected}
        >
          {children}
          {isAllocatableItemSelected && (
            <article className={styles.allocateToShiftContainer}>
              <PlusIcon />
              <Trans>Lägg till</Trans>
            </article>
          )}
        </DropZone>
      </LoadingOverlay>
    );
  }
};
