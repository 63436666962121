import { Trans } from "@lingui/react/macro";
import { Link } from "@components/Link/Link";
import clsx from "clsx";
import styles from "./AppSelector.module.scss";
import { Heading } from "@components/Heading/Heading";

type IAppSelector = {
  routes: ReadonlyArray<{
    icon: React.ReactNode;
    label: string;
    to: string;
  }>;
};

export const AppSelector = ({ routes }: IAppSelector) => {
  return (
    <div className={styles.container}>
      <img className={styles.logo} src="/logo.svg" alt="" />
      <Heading level="h1" className={styles.heading}>
        <Trans>Välkommen</Trans>
      </Heading>
      <p className={clsx(styles.subheading, "h2")}>
        <Trans>Välj applikation</Trans>
      </p>
      <div className={styles.buttons}>
        {routes.map((route) => (
          <Link key={route.to} className={styles.button} to={route.to}>
            {route.icon}
            {route.label}
          </Link>
        ))}
      </div>
    </div>
  );
};
