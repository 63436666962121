import { useSyncExternalStore } from "react";

const subscribe = (callback: () => void) => {
  window.addEventListener("storage", callback);
  return () => {
    window.removeEventListener("storage", callback);
  };
};

export const useLocalStorage = (key: string, initialValue: string) => {
  const storedValue = useSyncExternalStore(
    subscribe,
    () => localStorage.getItem(key) || initialValue,
  );

  const setValue = (value: string) => {
    localStorage.setItem(key, value);
    // Not sure why this is necessary, but without it the value is not reactive.
    window.dispatchEvent(new Event("storage"));
  };

  return [storedValue, setValue] as const;
};
