import { t } from "@lingui/core/macro";
import { closeActivity } from "@/api/Activities";
import { deducedError, displayErrorMessageAlert } from "@/Utils/ErrorUtils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router";

export const useCloseActivity = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { mutate, isPending } = useMutation({
    mutationFn: ({ activityId }: { activityId: string }) =>
      closeActivity(activityId),
    onError: (error) => {
      displayErrorMessageAlert(
        `${t`Gick inte att stänga aktiviteten.`} ${deducedError(error)}`,
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries();
      navigate(`..`);
    },
  });

  return {
    closeActivityMutation: mutate,
    isClosingActivity: isPending,
  };
};
