import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { patientKeys, updateInformation } from "@/api/Patients";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { FilledButton } from "@components/Button/Button";
import Form from "@/components/Form/Form";
import { Loading } from "@components/Loading/Loading";
import TextArea from "@/components/TextArea/TextArea";
import { deducedError } from "@/Utils/ErrorUtils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";

const ADDITIONAL_PATIENT_INFORMATION_TEMPLATE = `Diagnoskod: \nSituation: \nBakgrund: \nÖverkänslighet: \nFörsvårande omständigheter: \nIn/ut-farter: \nDosett till och med: ${new Date().getFullYear()}-MM-DD kl. HH:mm\nLäkemedelshantering: \nLifeCare: Ja/Nej\nHemtjänst: Ja/Nej\nBehov av måltidsleverans: \nHusdjur: \n`;

export const EditInformation = ({
  currentInformation,
  onSuccess,
  patientId,
}: {
  currentInformation: string | null | undefined;
  onSuccess: () => void;
  patientId: string;
}) => {
  const {
    formState: { errors, isDirty },
    getValues,
    handleSubmit,
    register,
    reset,
    setError,
  } = useForm({
    defaultValues: {
      information:
        currentInformation || ADDITIONAL_PATIENT_INFORMATION_TEMPLATE,
    },
  });

  const queryClient = useQueryClient();
  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: (information: string | null | undefined) =>
      updateInformation(patientId, information),
    onError: (error) => {
      setError("root.server", {
        message: deducedError(error),
      });
      // Reset `isDirty` to support only showing server error when the form is not changed.
      reset(getValues(), {
        keepErrors: true,
        keepIsSubmitted: true,
        keepTouched: true,
        keepIsValid: true,
        keepSubmitCount: true,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: patientKeys.detail(patientId),
      });
      onSuccess();
    },
  });

  return (
    <Form
      onSubmit={handleSubmit((formData) => {
        mutate(formData.information);
      })}
    >
      {isPending || isSuccess ? (
        <Loading message={t`Ändrar allmän information`} />
      ) : (
        <>
          {errors.root?.server?.message && !isDirty ? (
            <ErrorMessage message={errors.root.server.message} />
          ) : undefined}
          <Form.Row>
            <TextArea
              label={t`Allmän information (valfri)`}
              rows={ADDITIONAL_PATIENT_INFORMATION_TEMPLATE.split("\n").length}
              {...register("information")}
            />
          </Form.Row>
          <FilledButton type="submit">
            <Trans>Spara ändringar</Trans>
          </FilledButton>
        </>
      )}
    </Form>
  );
};
