import { msg } from "@lingui/core/macro";
import { z } from "zod";
import * as Sentry from "@sentry/react";
import { i18n } from "@lingui/core";
import type { MessageDescriptor } from "@lingui/core";

export const knownErrorCodeSchema = z.enum([
  // Common
  "InvalidBodyFormat",
  "InvalidEventStream",
  "InvalidJson",
  "InvalidPersonalIdentityNumber",
  "InvalidStreamType",
  "PreconditionFailed",
  "UnhandledException",
  // Logistics
  "AbaxNotFound",
  "ActiveVisitInSameRoute",
  "ActivityClosed",
  "ActivityInUnfinishedVisit",
  "ActivityNotFound",
  "ActivityOccurrenceInRoute",
  "AlreadyTravelledToVisit",
  "AlreadyTravellingToVisit",
  "AuthenticationFailed",
  "EmptyEventStream",
  "EmptyVisit",
  "EntityConflict",
  "FailedCosmosCall",
  "FinishedVisit",
  "InvalidActivityCategory",
  "InvalidActivityOccurrenceStatus",
  "InvalidDate",
  "InvalidDateTime",
  "InvalidEndDate",
  "InvalidMedicalCompetence",
  "InvalidName",
  "InvalidPatientStatus",
  "InvalidRouteStatus",
  "InvalidStartDate",
  "InvalidStartTime",
  "InvalidTime",
  "InvalidVisitStatus",
  "MissingCompetence",
  "MissingEmployeeId",
  "MissingHeader",
  "MissingQueryParameter",
  "MissingRoute",
  "MissingTitle",
  "OccurrenceAlreadyAssigned",
  "OccurrenceFinished",
  "OccurrenceGroupNotFound",
  "OccurrenceNotAssigned",
  "OccurrenceNotAllocated",
  "OccurrenceNotFinished",
  "OccurrenceOutsideOfSchedule",
  "OccurrenceOutsideOfRouteSchedule",
  "OccurrenceNotFound",
  "OccurrencePassed",
  "OccurrenceRemoved",
  "OneOrMoreVisitsNotFinished",
  "OngoingVisit",
  "PatientNotFound",
  "ScheduleNotFound",
  "UnstartedTripToVisit",
  "UnstartedVisit",
  "VisitNotFound",
  "VisitRemoved",
  // Patient
  "DuplicateHealthcareJourneyNumber",
  "DuplicatePersonalIdentityNumber",
  "InvalidEdit",
  "InvalidHealthcareJourneyNumber",
  "InvalidPhoneNumber",
  "InvalidSafetyAlarm",
  "OperationFailed",
  "PatientNotFound",
  "PhoneNumber0AfterCountryCodeNotAllowed",
  "PhoneNumberMustBeNumeric",
  "PhoneNumberMustStartWith00",
  "PhoneNumberEmpty",
  "RelativeIdConflict",
  "RelationMissing",
  "RelativeMissing",
  "RelativeNameMissing",
]);
type KnownErrorCode = z.infer<typeof knownErrorCodeSchema>;
export const knownErrorCodeDictionary: Record<
  KnownErrorCode,
  {
    issue: MessageDescriptor | "DEFAULT";
    resolution: MessageDescriptor | "NONE" | "DEFAULT";
  }
> = {
  // Common
  InvalidBodyFormat: {
    issue: "DEFAULT",
    resolution: "DEFAULT",
  },
  InvalidEventStream: {
    issue: "DEFAULT",
    resolution: "DEFAULT",
  },
  InvalidJson: {
    issue: "DEFAULT",
    resolution: "DEFAULT",
  },
  InvalidPersonalIdentityNumber: {
    issue: msg`Felaktigt personnummer.`,
    resolution: msg`Ange ett giltigt personnummer på formen ÅÅÅÅMMDDXXXX.`,
  },
  InvalidStreamType: {
    issue: "DEFAULT",
    resolution: "DEFAULT",
  },
  PreconditionFailed: {
    issue: msg`Detta har ändrats sedan sidan laddades in.`,
    resolution: msg`Ladda om sidan och försök igen.`,
  },
  UnhandledException: {
    issue: "DEFAULT",
    resolution: "DEFAULT",
  },

  // Logistics
  AbaxNotFound: {
    issue: msg`ABAX går inte att nå.`,
    resolution: "DEFAULT",
  },
  ActiveVisitInSameRoute: {
    issue: msg`Det finns redan ett pågående besök i denna rutt.`,
    resolution: msg`Stäng det besöket och försök sedan igen.`,
  },
  ActivityClosed: {
    issue: msg`Åtgärden kan inte utföras eftersom aktiviteten har avslutats.`,
    resolution: "NONE",
  },
  ActivityInUnfinishedVisit: {
    issue: msg`Ett eller flera aktivitetstillfällen är inplanerade i besök som inte är genomförda.`,
    resolution: msg`Se till att de aktivitetstillfällen som är inplanerade i oavslutade besök tas bort ur planen, och försök sedan igen.`,
  },
  ActivityNotFound: {
    issue: msg`Aktiviteten kan inte hittas.`,
    resolution: msg`Ladda om sidan och prova igen.`,
  },
  ActivityOccurrenceInRoute: {
    issue: msg`Aktivitetstillfället är inplanerat i en rutt som inte är avslutad.`,
    resolution: msg`Se till att aktivitetstillfället tas bort ur rutten, och försök sedan igen.`,
  },
  AlreadyTravelledToVisit: {
    issue: msg`Resan till besöket är redan genomförd.`,
    resolution: msg`Ladda om sidan för att se det senaste.`,
  },
  AlreadyTravellingToVisit: {
    issue: msg`Resan till besöket är redan påbörjad.`,
    resolution: msg`Ladda om sidan för att se det senaste.`,
  },
  AuthenticationFailed: {
    issue: "DEFAULT",
    resolution: "DEFAULT",
  },
  EmptyEventStream: {
    issue: "DEFAULT",
    resolution: "DEFAULT",
  },
  EmptyVisit: {
    issue: "DEFAULT",
    resolution: "DEFAULT",
  },
  EntityConflict: {
    issue: "DEFAULT",
    resolution: "DEFAULT",
  },
  FailedCosmosCall: {
    issue: "DEFAULT",
    resolution: "DEFAULT",
  },
  FinishedVisit: {
    issue: msg`Besöket är slutfört.`,
    resolution: msg`Ladda om sidan för att se det senaste.`,
  },
  InvalidActivityCategory: {
    // Won't happen unless developer error.
    issue: "DEFAULT",
    resolution: "DEFAULT",
  },
  InvalidActivityOccurrenceStatus: {
    // Backend code not in use at time of commit.
    issue: "DEFAULT",
    resolution: "DEFAULT",
  },
  InvalidDate: {
    issue: msg`Felaktigt datum.`,
    resolution: "NONE",
  },
  InvalidDateTime: {
    // Behind the scenes.
    issue: "DEFAULT",
    resolution: "DEFAULT",
  },
  InvalidEndDate: {
    issue: msg`Felaktigt slutdatum.`,
    resolution: "NONE",
  },
  InvalidMedicalCompetence: {
    // Won't happen unless developer error.
    issue: "DEFAULT",
    resolution: "DEFAULT",
  },
  InvalidName: {
    // Is used for changing route namn at time of commit.
    // Too generic name.
    issue: "DEFAULT",
    resolution: "DEFAULT",
  },
  InvalidPatientStatus: {
    // Behind the scenes.
    issue: "DEFAULT",
    resolution: "DEFAULT",
  },
  InvalidRouteStatus: {
    // Behind the scenes.
    issue: "DEFAULT",
    resolution: "DEFAULT",
  },
  InvalidStartDate: {
    issue: msg`Angivet datum har redan passerat.`,
    resolution: msg`Ange ett datum i framtiden.`,
  },
  InvalidStartTime: {
    issue: msg`Angiven tidpunkt har redan passerat.`,
    resolution: msg`Ange en tid i framtiden och försök igen.`,
  },
  InvalidTime: {
    issue: msg`Felaktigt tidsformat.`,
    resolution: "NONE",
  },
  InvalidVisitStatus: {
    // Behind the scenes
    issue: "DEFAULT",
    resolution: "DEFAULT",
  },
  MissingCompetence: {
    issue: msg`Aktiviteten saknar kompetenskrav.`,
    resolution: msg`Ange minst en kompetens.`,
  },
  MissingEmployeeId: {
    // Planday, Azure AD not integrated (id not correctly supplied)
    issue: msg`Din profil saknar koppling till schemaläggningstjänsten.`,
    resolution: msg`Kontakta teknisk support.`,
  },
  MissingHeader: {
    issue: "DEFAULT",
    resolution: "DEFAULT",
  },
  MissingQueryParameter: {
    issue: "DEFAULT",
    resolution: "DEFAULT",
  },
  MissingRoute: {
    issue: msg`Rutten finns inte.`,
    resolution: msg`Ladda om sidan för att se det senaste.`,
  },
  MissingTitle: {
    // Handled by UI
    issue: "DEFAULT",
    resolution: "DEFAULT",
  },
  OccurrenceAlreadyAssigned: {
    issue: msg`Aktiviteten har redan planerats in i en rutt.`,
    resolution: msg`Ladda om sidan för att se det senaste.`,
  },
  OccurrenceFinished: {
    issue: msg`Aktiviteten har avslutats.`,
    resolution: msg`Ladda om sidan för att se det senaste.`,
  },
  OccurrenceGroupNotFound: {
    issue: msg`Hittade inte besöket.`,
    resolution: msg`Ladda om sidan och försök igen.`,
  },
  OccurrenceNotAssigned: {
    issue: msg`Aktiviteten har inte tilldelats någon.`,
    resolution: "NONE",
  },
  OccurrenceNotAllocated: {
    issue: msg`Aktiviteten har inte tilldelats någon.`,
    resolution: msg`Lägg till den som utförde aktiviteten och försök igen.`,
  },
  OccurrenceNotFinished: {
    issue: msg`Aktivitetstillfället är inte avslutat.`,
    resolution: msg`Ladda om sidan för att se det senaste.`,
  },
  OccurrenceOutsideOfSchedule: {
    issue: msg`Kunde inte ändra schemat för aktiviteten. Det kan bero på att det finns tillfällen av aktiviteten som har en ändrad tid, eller som redan är tilldelade till en utförare.`,
    resolution: msg`Försök igen när dessa tillfällen har passerat.`,
  },
  OccurrenceOutsideOfRouteSchedule: {
    issue: msg`Du kan inte byta dag på ett aktivitetstillfälle som redan ligger i en rutt.`,
    resolution: msg`Ta bort aktivitetstillfället från rutten och försök igen.`,
  },
  OccurrenceNotFound: {
    issue: msg`Hittade inte aktivitetstillfället`,
    resolution: "DEFAULT",
  },
  OccurrencePassed: {
    issue: msg`Tiden för aktivitetstillfället har redan passerat.`,
    resolution: "NONE",
  },
  OccurrenceRemoved: {
    issue: msg`Aktivitetstillfället är borttaget`,
    resolution: msg`Ladda om sidan för att se det senaste.`,
  },
  OneOrMoreVisitsNotFinished: {
    issue: msg`Ett eller flera av ruttens besök är inte avslutade.`,
    resolution: msg`Se till att samtliga besök är genomförda och försök igen.`,
  },
  OngoingVisit: {
    issue: msg`Besök är pågående.`,
    resolution: msg`Prata med berörd kollega om ändringar ändå krävs.`,
  },
  PatientNotFound: {
    issue: msg`Hittade inte patienten.`,
    resolution: "DEFAULT",
  },
  ScheduleNotFound: {
    issue: msg`Hittade inte schema.`,
    resolution: "DEFAULT",
  },
  UnstartedTripToVisit: {
    issue: msg`Resan till besöket har inte påbörjats.`,
    resolution: msg`Säkerställ att resan har påbörjats innan du fortsätter.`,
  },
  UnstartedVisit: {
    issue: msg`Besöket är inte påbörjat.`,
    resolution: msg`Säkerställ att besöket har påbörjats innan du fortsätter.`,
  },
  VisitNotFound: {
    issue: msg`Besöket hittades inte. Kanske har det tagits bort, eller så har det aldrig funnits.`,
    resolution: msg`Stäm av med ledningscentralen vid behov.`,
  },
  VisitRemoved: {
    issue: msg`Besöket är borttaget.`,
    resolution: msg`Stäm av med ledningscentralen vid behov.`,
  },

  // Patient
  DuplicateHealthcareJourneyNumber: {
    issue: msg`Sjukresenumret används av en annan patient.`,
    resolution: msg`Ta ett nytt sjukresekort och försök igen.`,
  },
  DuplicatePersonalIdentityNumber: {
    issue: msg`Det finns redan en aktiv patient med det angivna personnumret.`,
    resolution: msg`Dubbelkolla personnumret och hör av dig till teknisk support om det borde fungera.`,
  },
  InvalidEdit: {
    issue: msg`Felaktig ändring.`,
    resolution: msg`Om du försökte ändra flera saker på samma gång, försök att ändra en sak i taget istället.`,
  },
  InvalidHealthcareJourneyNumber: {
    issue: msg`Felaktigt sjukresenummer.`,
    resolution: msg`Kontrollera att sjukresenumret är korrekt och försök igen.`,
  },
  InvalidPhoneNumber: {
    issue: msg`Telefonnumret är felaktigt.`,
    resolution: msg`Kontrollera att telefonnumret är korrekt och försök igen.`,
  },
  InvalidSafetyAlarm: {
    // Empty string submitted. Doesn't happen and if it does it is an implementation error.
    issue: "DEFAULT",
    resolution: "DEFAULT",
  },
  OperationFailed: {
    // Behind the scenes.
    issue: "DEFAULT",
    resolution: "DEFAULT",
  },
  PhoneNumber0AfterCountryCodeNotAllowed: {
    issue: msg`Telefonnumret får inte börja med 0 efter landskoden.`,
    resolution: msg`Kontrollera att telefonnumret är korrekt och försök igen.`,
  },
  PhoneNumberMustBeNumeric: {
    issue: msg`Telefonnumret får bara innehålla siffror.`,
    resolution: msg`Kontrollera att telefonnumret är korrekt och försök igen.`,
  },
  PhoneNumberMustStartWith00: {
    // Domain knowledge: server only handles 00, client transforms from/to that format.
    issue: msg`Telefonnumret måste börja med 0, eller 00 följt av landskod.`,
    resolution: msg`Kontrollera att telefonnumret är korrekt och försök igen.`,
  },
  PhoneNumberEmpty: {
    issue: msg`Telefonnumret får inte vara tomt.`,
    resolution: msg`Kontrollera att telefonnumret är korrekt och försök igen.`,
  },
  RelationMissing: {
    issue: msg`Anhörigs relation saknas.`,
    resolution: msg`Specificera den anhörigas relation till patienten.`,
  },
  RelativeIdConflict: {
    issue: msg`Anhörig med samma id existerar redan.`,
    resolution: msg`Ladda om sidan och försök igen.`,
  },
  RelativeMissing: {
    issue: msg`Anhörig hittades inte.`,
    resolution: "DEFAULT",
  },
  RelativeNameMissing: {
    issue: msg`Anhörig saknar namn.`,
    resolution: msg`Ange namn för den anhöriga.`,
  },
};

const internalErrorCodeSchema = z.object({
  response: z.object({
    data: z.object({
      code: knownErrorCodeSchema,
    }),
  }),
});

const internalErrorStatusSchema = z.object({
  response: z.object({
    status: z.number(),
  }),
});

export const isErrorWithKnownErrorCode = (
  error: unknown,
): error is { response: { data: { code: KnownErrorCode } } } => {
  return internalErrorCodeSchema.safeParse(error).success;
};

export const isErrorWithStatus = (
  error: unknown,
): error is { response: { status: number } } => {
  return internalErrorStatusSchema.safeParse(error).success;
};

export const isUnauthenticatedError = (error: unknown) => {
  return isErrorWithStatus(error) && error.response.status === 401;
};

export const deducedError = (error: unknown) => {
  const defaultUserFacingErrorExplanation = msg`Något gick fel.`;
  const defaultUserFacingErrorResolution = msg`Försök igen om en liten stund och hör av dig till teknisk support om det ändå inte fungerar.`;

  if (isErrorWithKnownErrorCode(error)) {
    const { issue, resolution } =
      knownErrorCodeDictionary[error.response.data.code];
    const userFacingErrorExplanation = i18n._(
      issue === "DEFAULT" ? defaultUserFacingErrorExplanation : issue,
    );
    const userFacingErrorResolution =
      resolution === "NONE"
        ? ""
        : resolution === "DEFAULT"
          ? i18n._(defaultUserFacingErrorResolution)
          : i18n._(resolution);
    return `${userFacingErrorExplanation}${userFacingErrorResolution ? ` ${userFacingErrorResolution}` : ""}`;
  }

  return `${i18n._(defaultUserFacingErrorExplanation)} ${i18n._(defaultUserFacingErrorResolution)}`;
};

export const displayErrorMessageAlert = (message: string) => {
  Sentry.captureException(new Error(`An error alert was shown: ${message}`));
  alert(message);
};
