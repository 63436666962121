import { msg } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import type { IRoute } from "@/api/Routes";
import { finishRoute, fetchRoute, routeKeys } from "@/api/Routes";
import { FilledButton } from "@components/Button/Button";
import CheckmarkIcon from "@components/icons/CheckmarkIcon";
import {
  deducedError,
  displayErrorMessageAlert,
  isErrorWithKnownErrorCode,
  knownErrorCodeSchema,
} from "@/Utils/ErrorUtils";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import styles from "./Route.module.scss";
import VisitCard from "./VisitCard";
import { Heading } from "@components/Heading/Heading";
import * as Sentry from "@sentry/react";
import { useLingui } from "@lingui/react";
import { i18n } from "@lingui/core";

interface IRouteComponent {
  initialRoute: IRoute;
}

const RouteFinishedAt = ({ finishedAt }: { finishedAt: Date }) => {
  const finishedTime = i18n.date(finishedAt, { timeStyle: "short" });
  return <Trans>Rutten slutfördes klockan {finishedTime}</Trans>;
};

const Route = ({ initialRoute }: IRouteComponent) => {
  const { _ } = useLingui();
  const queryClient = useQueryClient();
  const { data: route } = useQuery({
    queryKey: routeKeys.detail(initialRoute.id),
    queryFn: () => {
      return fetchRoute(initialRoute.id);
    },
    initialData: initialRoute,
  });
  const { id, finishedAt, name, status, visits } = route;
  const ongoingVisits = visits.filter(
    ({ status }) => status === "travellingTo" || status === "ongoing",
  );
  const upcomingVisitId =
    ongoingVisits.length === 0
      ? visits.find((visit) => visit.status === "planned")?.id
      : undefined;
  const allVisitsFinished = visits.every(
    (visit) => visit.status === "finished",
  );

  const { mutate: finishRouteMutation, isPending: isFinishing } = useMutation({
    mutationFn: ({ routeId }: { routeId: string }) => {
      return finishRoute(routeId);
    },
    // MED-2243 - prefer other ways of resolving errors than displaying alerts
    onError: (error) => {
      if (
        isErrorWithKnownErrorCode(error) &&
        error.response.data.code === knownErrorCodeSchema.Values.MissingRoute
      ) {
        Sentry.captureException(
          "Failed to finish route. Route was already finished (or missing at least). Refetching.",
        );
        return queryClient.refetchQueries({ queryKey: routeKeys.all });
      } else {
        displayErrorMessageAlert(
          `${_(msg`Gick inte att avsluta rutten.`)} ${deducedError(error)}`,
        );
      }
    },
    onSuccess: () => {
      return queryClient.refetchQueries({ queryKey: routeKeys.all });
    },
  });

  return (
    <article className={styles.route}>
      <Heading level="h2">{name}</Heading>
      <ol className={styles.visitList}>
        {visits.map((visit) => {
          return (
            <VisitCard
              key={visit.id}
              isUpcomingVisit={upcomingVisitId === visit.id}
              routeId={id}
              visit={visit}
            />
          );
        })}
      </ol>
      {status === "finished" ? (
        finishedAt ? (
          <RouteFinishedAt finishedAt={finishedAt} />
        ) : (
          <Trans>Rutten slutfördes</Trans>
        )
      ) : allVisitsFinished ? (
        <FilledButton
          disabled={isFinishing}
          onClick={() => {
            finishRouteMutation({ routeId: id });
          }}
          width="fill-container"
        >
          <CheckmarkIcon />
          <Trans>Slutför rutt</Trans>
        </FilledButton>
      ) : undefined}
    </article>
  );
};

export default Route;
