import clsx from "clsx";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import styles from "./RadioButtons.module.scss";

interface IRadioButtons {
  children: React.ReactNode;
  errorMessage?: string;
  icon?: React.ReactNode;
  legend?: string;
  orientation?: "horizontal" | "vertical";
  width?: "hug-content" | "fill-container";
}
const RadioButtons = ({
  children,
  errorMessage,
  icon,
  legend,
  orientation = "vertical",
  width = "hug-content",
}: IRadioButtons) => {
  return (
    <fieldset className={styles.radioButtonsFieldset}>
      {icon || legend ? (
        <legend className={styles.legend}>
          {icon}
          {legend}
        </legend>
      ) : undefined}
      {errorMessage ? (
        <div className={styles.errorMessage}>
          <ErrorMessage message={errorMessage} weight="bold" />
        </div>
      ) : undefined}
      <div
        className={clsx(
          styles.radioButtons,
          styles[orientation],
          styles[`width-${width}`],
        )}
      >
        {children}
      </div>
    </fieldset>
  );
};

export default RadioButtons;
