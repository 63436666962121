import { updateTitle } from "@/api/Activities";
import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { FilledButton } from "@components/Button/Button";
import Form from "@/components/Form/Form";
import InputField from "@/components/InputField/InputField";
import { Loading } from "@components/Loading/Loading";
import { deducedError } from "@/Utils/ErrorUtils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { NUMBER_OF_CHARACTERS_ALLOWED_IN_TITLE } from "@/forms/AddActivityForm/TitleInput";

export const EditTitle = ({
  currentTitle,
  onSuccess,
  activityId,
}: {
  currentTitle: string;
  onSuccess: () => void;
  activityId: string;
}) => {
  const {
    clearErrors,
    formState: { errors, isDirty },
    getValues,
    handleSubmit,
    register,
    reset,
    setError,
    trigger,
  } = useForm({
    defaultValues: {
      title: currentTitle,
    },
  });

  const queryClient = useQueryClient();
  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: (title: string) => updateTitle(activityId, title),
    onError: (error) => {
      setError("root.server", {
        message: deducedError(error),
      });
      // Reset `isDirty` to support only showing server error when the form is not changed.
      reset(getValues(), {
        keepErrors: true,
        keepIsSubmitted: true,
        keepTouched: true,
        keepIsValid: true,
        keepSubmitCount: true,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries();
      onSuccess();
    },
  });

  return (
    <Form
      onSubmit={handleSubmit((formData) => {
        mutate(formData.title);
      })}
    >
      {isPending || isSuccess ? (
        <Loading message={t`Ändrar titel`} />
      ) : (
        <>
          {errors.root?.server?.message && !isDirty ? (
            <ErrorMessage message={errors.root.server.message} />
          ) : null}
          <Form.Row>
            <InputField
              label={t`Namn`}
              errorMessage={errors.title?.message}
              {...register("title", {
                required: {
                  value: true,
                  message: t`Aktiviteten behöver ett namn`,
                },
                maxLength: {
                  value: NUMBER_OF_CHARACTERS_ALLOWED_IN_TITLE,
                  message: t`Namnet får max vara ${NUMBER_OF_CHARACTERS_ALLOWED_IN_TITLE} tecken. Lägg ytterligare text i beskrivningen.`,
                },
                onChange: (e) => {
                  // User feedback onChange
                  if (e?.currentTarget?.value?.length >= 1) {
                    trigger("title");
                  } else {
                    // ... but don't show annoying errors at this point if the field is empty.
                    clearErrors("title");
                  }
                },
              })}
            />
          </Form.Row>
          {isDirty ? (
            <FilledButton type="submit">
              <Trans>Spara ändringar</Trans>
            </FilledButton>
          ) : (
            <></>
          )}
        </>
      )}
    </Form>
  );
};
