import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { useActivePatientsWithHomeVisitFlag } from "@/api/Patients";
import type {
  CircleLayerSpecification,
  SymbolLayerSpecification,
  FillLayerSpecification,
  LineLayerSpecification,
} from "mapbox-gl";
import { HOSPITAL_COORDINATES } from "@/Utils/EnvUtils";
import Map from "@/components/Map/Map";
import { format } from "@models/date-and-time";
import { useContext, useEffect, useMemo } from "react";
import { Layer, Source } from "react-map-gl";
import { getVisitsCoordinates, useRoutes } from "@/api/Routes";
import { useVehicles } from "@/api/Vehicles";
import { useSelectedDate } from "@/Utils/useSelectedDate";
import { useDirections } from "@/api/Routes";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { useLingui } from "@lingui/react";
import { ShiftsContext } from "./ShiftsContext";
import { patientStatusSchema } from "@models/patients";
import styles from "./ShiftsMap.module.scss";

const formatter = new Intl.ListFormat(navigator.language, {
  style: "long",
  type: "disjunction",
});

const lineLayer: LineLayerSpecification = {
  source:
    "String needed. Check this issue: https://github.com/visgl/react-map-gl/issues/2411",
  id: "route-visualization",
  type: "line",
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
  paint: {
    "line-color": ["get", "color"],
    "line-opacity": ["get", "opacity"],
    "line-width": 4,
  },
};

const symbolLayer: SymbolLayerSpecification = {
  source:
    "String needed. Check this issue: https://github.com/visgl/react-map-gl/issues/2411",
  id: "route-labels",
  type: "symbol",
  layout: {
    "text-field": ["get", "name"],
    "text-offset": [0, 1],
    "text-size": ["get", "size"],
  },
  paint: {
    "text-color": ["get", "color"],
    "text-halo-color": "#fff",
    "text-halo-width": 3,
  },
};

const clusterLayer = (
  entity: "patient" | "vehicle",
): CircleLayerSpecification => {
  return {
    source:
      "String needed. Check this issue: https://github.com/visgl/react-map-gl/issues/2411",
    id: `${entity}-clusters`,
    type: "circle",
    filter: ["has", "point_count"],
    paint: {
      "circle-color":
        entity === "patient"
          ? [
              "case",
              ["!=", ["get", "examined_patient_name"], null],
              "#00328a", // blue-40 for selected patient clusters
              ["==", ["get", "has_home_visit"], false],
              "#a3a3a3", // gray-70 for patient clusters without home visits
              ["==", ["get", "point_count"], 2],
              "#0d8f2a", // green-50 for clusters with 2 patients
              "#047003", // green-40 for clusters with more than 2 patients
            ]
          : "#FF8921", // orange-60 for vehicle clusters
      "circle-radius":
        entity === "patient"
          ? [
              "case",
              [
                "any",
                ["==", ["get", "has_home_visit"], true],
                ["!=", ["get", "examined_patient_name"], null],
              ],
              10, // patient cluster with home visit or selected patient cluster
              5, // patient cluster without home visits
            ]
          : 10, // vehicle cluster
      "circle-stroke-width": 2,
      "circle-stroke-color":
        entity === "patient"
          ? [
              "case",
              ["!=", ["get", "examined_patient_name"], null],
              "#042662", // dark blue stroke for selected patient cluster
              ["==", ["get", "has_home_visit"], true],
              "#045C03", // darker green stroke for clusters with home visit
              "#757575", // gray-50 stroke for clusters without home visits
            ]
          : "#C15B00", // orange-40 stroke for vehicles
      "circle-opacity": 0.8,
    },
  };
};

const circleNameLayer = (
  entity: "patient" | "vehicle",
): SymbolLayerSpecification => {
  return {
    source:
      "String needed. Check this issue: https://github.com/visgl/react-map-gl/issues/2411",
    id: `${entity}-cluster-count`,
    type: "symbol",
    layout: {
      // If there is a `name` field, use it, otherwise use the `point_count` field (e.g. 2) and append " bilar" or " patienter"
      "text-field": [
        "coalesce",
        ["get", "name"],
        [
          "case",
          ["!=", ["get", "examined_patient_name"], null],
          [
            "concat",
            ["get", "examined_patient_name"],
            " + ",
            ["to-string", ["-", ["get", "point_count"], 1]],
            entity === "vehicle" ? " " + t`bilar` : " " + t`patienter`,
          ],
          [
            "concat",
            ["get", "point_count"],
            entity === "vehicle" ? " " + t`bilar` : " " + t`patienter`,
          ],
        ],
      ],
      // -1.5 puts the label over the circle instead of on top of it. Dependent on "circle-radius": 10,
      "text-offset": [0, -1.5],
      "text-size": 12,
    },
    paint: {
      "text-halo-color": "#fff",
      "text-halo-width": 1.5,
    },
  };
};

const unclusteredLayer = (
  entity: "patient" | "vehicle",
): CircleLayerSpecification => {
  return {
    source:
      "String needed. Check this issue: https://github.com/visgl/react-map-gl/issues/2411",
    id: `${entity}-unclustered-point`,
    type: "circle",
    // Only show unclustered points when not part of a cluster
    filter: ["!", ["has", "point_count"]],
    paint: {
      "circle-color":
        entity === "vehicle"
          ? "#FF8921" // orange-60 for vehicles
          : [
              "case",
              ["!=", ["get", "examined_patient_name"], null],
              "#00328a", // blue-40 for selected patient
              ["==", ["get", "has_home_visit"], true],
              "#1aa339", // green-60 for patients with home visit
              "#a3a3a3", // gray-50 for patients without home visit
            ],
      "circle-radius":
        entity === "patient"
          ? [
              "case",
              [
                "any",
                ["==", ["get", "has_home_visit"], true],
                ["!=", ["get", "examined_patient_name"], null],
              ],
              10, // patient has home visit or is selected
              5, // patient without home visit
            ]
          : 10, // vehicles
      "circle-stroke-width": 2,
      "circle-stroke-color":
        entity === "patient"
          ? [
              "case",
              ["!=", ["get", "examined_patient_name"], null],
              "#042662", // dark blue for selected patient
              ["==", ["get", "has_home_visit"], true],
              "#045C03", // darker green for patients with home visit
              "#757575", // gray-50 for patients without home visit
            ]
          : "#C15B00", // orange-40 for vehicles
      "circle-opacity": 0.8,
    },
  };
};

const hospitalZone = {
  type: "Feature" as const,
  properties: {},
  geometry: {
    type: "Polygon" as const,
    coordinates: [
      [
        [18.02, 59.3325],
        [18.0235, 59.3324],
        [18.0245, 59.334],
        [18.0188, 59.3361],
        [18.017, 59.335],
        [18.02, 59.3325],
      ],
    ],
  },
};

const hospitalZoneLayer: FillLayerSpecification = {
  source:
    "String needed. Check this issue: https://github.com/visgl/react-map-gl/issues/2411",
  id: "hospital-zone",
  type: "fill",
  paint: {
    "fill-color": "#2d4ad9", // var(--color-primary-50)
    "fill-opacity": 0.3,
  },
};

export const ShiftsMap = () => {
  const { _ } = useLingui();
  const selectedDate = new Date(useSelectedDate());
  const {
    selectedItem,
    boundsShowingAllPatients,
    setBoundsShowingAllPatients,
  } = useContext(ShiftsContext);
  const formattedSelectedDate = format(selectedDate, "yyyy-MM-dd");

  const { data: activePatientsWithHomeVisitFlag, isError } =
    useActivePatientsWithHomeVisitFlag(
      formattedSelectedDate,
      formattedSelectedDate,
    );

  const { data: vehicles = [], isError: isErrorVehicles } = useVehicles();

  const { data: routes = [], isError: isErrorRoutes } = useRoutes({
    date: formattedSelectedDate,
  });

  const allRoutesCoordinates = routes.map(({ visits }) =>
    getVisitsCoordinates(visits),
  );
  const { data: directions } = useDirections(allRoutesCoordinates);

  const patientCoordinates = useMemo(() => {
    if (!activePatientsWithHomeVisitFlag) {
      return undefined;
    }
    return [
      ...(activePatientsWithHomeVisitFlag.map(
        (patient) => patient.address.coordinates,
      ) ?? []),
      HOSPITAL_COORDINATES,
    ];
  }, [activePatientsWithHomeVisitFlag]);

  useEffect(() => {
    if (!patientCoordinates) {
      return;
    }
    setBoundsShowingAllPatients([
      {
        lat: Math.min(...patientCoordinates.map(({ latitude }) => latitude)),
        lng: Math.min(...patientCoordinates.map(({ longitude }) => longitude)),
      },
      {
        lat: Math.max(...patientCoordinates.map(({ latitude }) => latitude)),
        lng: Math.max(...patientCoordinates.map(({ longitude }) => longitude)),
      },
    ] as const);
  }, [patientCoordinates, setBoundsShowingAllPatients]);

  const formattedErrors = formatter.format(
    [
      isError ? t`patienterna` : "",
      isErrorRoutes ? t`rutterna` : "",
      isErrorVehicles ? t`bilarna` : "",
    ].filter((errorMessage) => errorMessage.length > 0),
  );
  const customErrorMessage = [isError, isErrorRoutes, isErrorVehicles].some(
    (error) => error,
  )
    ? t`Kunde inte visa ${formattedErrors} i kartan. Försök att ladda om sidan, och kontakta teknisk support om det ändå inte fungerar.`
    : null;

  const selectedRouteId =
    selectedItem &&
    (selectedItem.type === "routeOnly" ||
      selectedItem.type === "shiftRouteItem")
      ? selectedItem.routeId
      : null;

  const selectedPatientId =
    selectedItem && selectedItem.type !== "routeOnly"
      ? selectedItem.item.patient?.id
      : null;

  const selectedRouteTravelTime = useMemo(() => {
    if (!selectedItem || !("routeId" in selectedItem)) {
      return null;
    }
    const routeIndex = routes.findIndex(
      ({ id }) => id === selectedItem.routeId,
    );
    const carDirections = directions?.[routeIndex];
    if (!carDirections) {
      return null;
    }
    return carDirections.legDurations.reduce((acc, curr) => acc + curr, 0);
  }, [directions, routes, selectedItem]);

  const routeLines = useMemo(() => {
    const visitCoordinates = routes.map((route) =>
      getVisitsCoordinates(route.visits).map(({ longitude, latitude }) => [
        longitude,
        latitude,
      ]),
    );
    return {
      type: "FeatureCollection" as const,
      features: routes.map((route, index) => {
        const carDirections = directions?.[index];
        const birdDirections = visitCoordinates[index]
          ? [
              [HOSPITAL_COORDINATES.longitude, HOSPITAL_COORDINATES.latitude],
              ...visitCoordinates[index],
              [HOSPITAL_COORDINATES.longitude, HOSPITAL_COORDINATES.latitude],
            ]
          : [];

        return {
          type: "Feature" as const,
          properties: {
            color:
              route.id === selectedRouteId
                ? "#2d4ad9" // var(--color-primary-50)
                : "#3d3d3d", // var(--color-neutral-10)
            opacity: route.id === selectedRouteId ? 1 : 0.2,
          },
          geometry: {
            type: "LineString" as const,
            coordinates: carDirections?.coordinates ?? birdDirections,
          },
        };
      }),
    };
  }, [routes, directions, selectedRouteId]);

  const routeLabels = useMemo(() => {
    const visitLabels = routes
      .filter((route) => route.visits.length >= 1)
      .flatMap((route) => {
        const coordinatesWithIndexes = route.visits.reduce(
          (coordinatesWithIndexes, visit, index) => {
            if (visit.patient.status === patientStatusSchema.Values.deleted) {
              return coordinatesWithIndexes;
            }

            const {
              patient: {
                address: { coordinates },
                id: patientId,
              },
            } = visit;

            if (coordinatesWithIndexes[patientId]) {
              return {
                ...coordinatesWithIndexes,
                [patientId]: {
                  coordinates: coordinates,
                  indexes: [
                    ...coordinatesWithIndexes[patientId].indexes,
                    index,
                  ],
                },
              };
            }
            return {
              ...coordinatesWithIndexes,
              [patientId]: {
                coordinates: coordinates,
                indexes: [index],
              },
            };
          },
          {} as Record<
            string,
            {
              coordinates: { longitude: number; latitude: number };
              indexes: Array<number>;
            }
          >,
        );

        return Object.entries(coordinatesWithIndexes).map(
          ([_, { coordinates, indexes }]) => ({
            type: "Feature" as const,
            properties: {
              name: indexes.map((index) => index + 1).join(", "),
              color: "#2d4ad9", // var(--color-primary-50)
              size: route.id === selectedRouteId ? 24 : 0, //px
            },
            geometry: {
              type: "Point" as const,
              coordinates: [coordinates.longitude, coordinates.latitude],
            },
          }),
        );
      });

    const routeLabels = {
      type: "FeatureCollection" as const,
      features: visitLabels,
    };
    return routeLabels;
  }, [selectedRouteId, routes]);

  const vehicleLabels = useMemo(() => {
    return {
      type: "FeatureCollection" as const,
      features:
        vehicles.map((vehicle) => ({
          type: "Feature" as const,
          properties: {
            name: vehicle.licensePlate,
            color: "#66bfff", // --color-primary-70
          },
          geometry: {
            type: "Point" as const,
            coordinates: [
              vehicle.location.longitude,
              vehicle.location.latitude,
            ],
          },
        })) ?? [],
    };
  }, [vehicles]);

  const patientLabels = useMemo(
    () => ({
      type: "FeatureCollection" as const,
      features: (activePatientsWithHomeVisitFlag ?? []).map(
        (activePatientWithHomeVisitFlag) => ({
          type: "Feature" as const,
          properties: {
            name: activePatientWithHomeVisitFlag.name,
            has_home_visit: activePatientWithHomeVisitFlag.hasHomeVisit,
            examined_patient_name:
              activePatientWithHomeVisitFlag.id === selectedPatientId
                ? activePatientWithHomeVisitFlag.name
                : null,
          },
          geometry: {
            type: "Point" as const,
            coordinates: [
              activePatientWithHomeVisitFlag.address.coordinates.longitude,
              activePatientWithHomeVisitFlag.address.coordinates.latitude,
            ],
          },
        }),
      ),
    }),
    [activePatientsWithHomeVisitFlag, selectedPatientId],
  );

  return (
    <>
      {customErrorMessage ? (
        <ErrorMessage message={customErrorMessage} />
      ) : (
        <> </>
      )}
      {boundsShowingAllPatients ? (
        <Map
          id="planningMap"
          style={{
            height: "100%",
          }}
          initialAreaToShow={{ bounds: boundsShowingAllPatients }}
          annotation={
            selectedItem && "routeId" in selectedItem ? (
              <Trans>
                <span className={styles.bold}>Total restid:</span> ca{" "}
                {selectedRouteTravelTime} minuter
              </Trans>
            ) : null
          }
        >
          <Source data={hospitalZone} type="geojson">
            <Layer {...hospitalZoneLayer} />
          </Source>
          <Source data={routeLines} type="geojson">
            <Layer {...lineLayer} />
          </Source>
          <Source data={routeLabels} type="geojson">
            <Layer {...symbolLayer} />
          </Source>
          <Source
            data={patientLabels}
            type="geojson"
            cluster={true}
            clusterRadius={20}
            clusterProperties={{
              // Coalesce returns the first non-null value, without it clusters would have the value of the last item clustered instead of any item with examined_patient_name set
              examined_patient_name: [
                "coalesce",
                ["get", "examined_patient_name"],
              ],
              // Any returns true if one or more inside the cluster has a home visit
              has_home_visit: ["any", ["get", "has_home_visit"]],
            }}
          >
            <Layer {...clusterLayer("patient")} />
            <Layer {...unclusteredLayer("patient")} />
            <Layer {...circleNameLayer("patient")} />
          </Source>
          <Source
            data={vehicleLabels}
            type="geojson"
            cluster={true}
            clusterRadius={20}
          >
            <Layer {...clusterLayer("vehicle")} />
            <Layer {...unclusteredLayer("vehicle")} />
            <Layer {...circleNameLayer("vehicle")} />
          </Source>
        </Map>
      ) : null}
    </>
  );
};
