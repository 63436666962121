import { t } from "@lingui/core/macro";
import { DaySwitcher } from "@/components/DaySwitcher/DaySwitcher";
import styles from "./Shifts.module.scss";
import { useEffect, useState } from "react";
import { useShiftsViewToolbar } from "./useShiftsViewToolbar";
import StandaloneCheckbox from "@/components/Checkbox/StandaloneCheckbox";
import { knownFeatureFlagsSchema, useFeatureFlag } from "@/api/FeatureFlags";
import { ShiftsSideBar } from "./SideBar/ShiftsSideBar";
import { ShiftsSection } from "./ShiftsSection";
import { ShiftsContextProvider } from "./ShiftsContextProvider";
import { MapProvider } from "react-map-gl";
import clsx from "clsx";
import { useUnplannedOccurrencesAndGroups } from "@/api/Activities";
import { useSelectedDate } from "@/Utils/useSelectedDate";
import { ShiftsMap } from "./ShiftsMap";
import { useMap } from "react-map-gl";

export type IAllocatableItemType = "group" | "activityOccurrence" | undefined;

export const Shifts = () => {
  return (
    <ShiftsContextProvider>
      <MapProvider>
        <InnerShifts />
      </MapProvider>
    </ShiftsContextProvider>
  );
};

const InnerShifts = () => {
  const [
    isExpanded,
    setIsExpanded,
    isMapShown,
    setIsMapShown,
    isToPlanShown,
    setIsToPlanShown,
  ] = useShiftsViewToolbar();
  const [isPendingAddToShift, setIsPendingAddToShift] = useState(false);

  const { planningMap } = useMap();

  useEffect(() => {
    if (!planningMap) {
      // planningMap is probably closed, or just not loaded, so this is a no-op
      return;
    } else {
      planningMap.resize();
    }
  }, [isToPlanShown, planningMap]);

  const { data: allocateInShiftsView } = useFeatureFlag(
    knownFeatureFlagsSchema.Values.AllocateInShiftsView,
  );

  const selectedDate = new Date(useSelectedDate());

  const {
    data: unplannedOccurrencesAndGroups,
    isPending,
    isError,
  } = useUnplannedOccurrencesAndGroups(
    selectedDate.toDateString(),
    selectedDate.toDateString(),
  );

  const numberOfUnplannedOccurrences =
    unplannedOccurrencesAndGroups?.length ?? 0;

  return (
    <section className={styles.container}>
      <section className={styles.toolbar}>
        <DaySwitcher size="h2" />
        {allocateInShiftsView ? (
          <StandaloneCheckbox
            label={
              isError || isPending
                ? t`Att planera`
                : t`Att planera (${numberOfUnplannedOccurrences})`
            }
            name="to-plan-view"
            checked={isToPlanShown}
            onChange={(e) => setIsToPlanShown(e.target.checked)}
          />
        ) : null}
        <StandaloneCheckbox
          label={t`Expanderat läge`}
          name="expanded-view"
          checked={isExpanded}
          onChange={(e) => setIsExpanded(e.target.checked)}
        />
        <StandaloneCheckbox
          label={t`Visa karta`}
          name="show-map"
          checked={isMapShown}
          onChange={(e) => setIsMapShown(e.target.checked)}
        />
      </section>

      <div className={styles.contentWrapper}>
        {allocateInShiftsView && isToPlanShown ? (
          <section className={styles.toPlan}>
            <ShiftsSideBar isPendingAddToShift={isPendingAddToShift} />
          </section>
        ) : null}
        <div
          className={clsx(
            styles.shiftsAndMap,
            allocateInShiftsView && isToPlanShown ? "" : styles.sideBarHidden,
            isMapShown ? "" : styles.mapHidden,
          )}
        >
          <div className={styles.shifts}>
            <ShiftsSection
              isExpanded={isExpanded}
              onPendingAddToShift={setIsPendingAddToShift}
            />
          </div>
          {isMapShown ? <ShiftsMap /> : null}
        </div>
      </div>
    </section>
  );
};
