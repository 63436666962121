import { visitStatusDictionary } from "@models/visits";
import {
  activityOccurrenceStatusSchema,
  activityOccurrenceStatusDictionary,
  visitStatusSchema,
} from "@models/activities";
import z from "zod";

const _statusSchema = z.union([
  activityOccurrenceStatusSchema,
  visitStatusSchema,
]);
export type IStatus = z.infer<typeof _statusSchema>;

export const statusDictionary = {
  ...activityOccurrenceStatusDictionary,
  ...visitStatusDictionary,
};
