import { Trans } from "@lingui/react/macro";
import { msg, t } from "@lingui/core/macro";
import { useParams } from "react-router";
import { patientKeys, removeRelative, usePatient } from "@/api/Patients";
import Address from "@/components/Address/Address";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { Loading } from "@components/Loading/Loading";
import styles from "./Information.module.scss";
import type { IPlainButton } from "@components/Button/Button";
import { PlainButton } from "@components/Button/Button";
import { useState } from "react";
import { EditPhoneNumber } from "@/forms/EditPhoneNumber";
import { EditAddress } from "@/forms/EditAddress";
import { EditInformation } from "@/forms/EditInformation";
import { AddRelative } from "@/forms/AddRelative";
import DropdownMenu from "@/components/DropdownMenu/DropdownMenu";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deducedError, displayErrorMessageAlert } from "@/Utils/ErrorUtils";
import { EditHealthcareJourneyNumber } from "@/forms/EditHealthcareJourneyNumber";
import { EditSafetyAlarmText } from "@/forms/EditSafetyAlarm";
import PlusIcon from "@components/icons/PlusIcon";
import { z } from "zod";
import { Heading } from "@components/Heading/Heading";
import { EditName } from "@/forms/EditName";
import { dateName } from "@/Utils/DateUtils";
import { EditWard } from "@/forms/EditWard";
import { knownFeatureFlagsSchema, useFeatureFlag } from "@/api/FeatureFlags";
import * as Sentry from "@sentry/react";
import { useLingui } from "@lingui/react";
import { patientTypeDictionary } from "@models/patients";
import { EditArea } from "@/forms/EditArea";

const Information = () => {
  const { _ } = useLingui();
  const { data: ASIHCustomer } = useFeatureFlag(
    knownFeatureFlagsSchema.Values.ASIHCustomer,
  );
  const { patientId } = z.object({ patientId: z.string() }).parse(useParams());
  const [currentlyEditing, setCurrentlyEditing] = useState<
    | "address"
    | "phonenumber"
    | "healthcareJourneyNumber"
    | "information"
    | "add-relative"
    | "safety-alarm"
    | "name"
    | "ward"
    | "area"
    | null
  >(null);
  const { data: patient, isPending, isError, error } = usePatient(patientId);
  const { data: cardiologyEnabled } = useFeatureFlag(
    knownFeatureFlagsSchema.Values.CardiologyWorkflow,
  );

  const queryClient = useQueryClient();
  const { mutate: removeRelativeMutation, isPending: isRemovingRelative } =
    useMutation({
      mutationFn: ({
        relativeId,
        patientId,
      }: {
        relativeId: string;
        patientId: string;
      }) => removeRelative(patientId, relativeId),
      onError: (error) => {
        displayErrorMessageAlert(
          `${t`Gick inte att ta bort anhörig.`} ${deducedError(error)}`,
        );
      },
      onSuccess: (_, { patientId }) => {
        queryClient.invalidateQueries({
          queryKey: patientKeys.detail(patientId),
        });
      },
    });

  if (isPending) {
    return <Loading message={_(msg`Laddar patientinformation`)} padding={24} />;
  }
  if (isError) {
    Sentry.captureException(error);
    return <ErrorMessage message={deducedError(error)} />;
  }

  const EditButton = ({
    children,
    onClick,
  }: Pick<IPlainButton, "children" | "onClick">) => (
    <PlainButton size="small" weight="light" onClick={onClick}>
      {children}
    </PlainButton>
  );

  const DateOf = () => {
    const { status } = patient;
    const [heading, content] =
      status === "prospect"
        ? [t`Registreringsdatum`, dateName(patient.registeredAt)]
        : status === "admitted"
          ? [t`Inskrivningsdatum`, dateName(patient.admittedAt)]
          : status === "discharged"
            ? [t`Utskrivningsdatum`, dateName(patient.dischargedAt)]
            : [t`[Rubrik saknas]`, "-"];

    return (
      <>
        <section className={styles.informationSection}>
          <Heading level="h2">{heading}</Heading>
          {content}
        </section>
        {ASIHCustomer ? (
          <section className={styles.informationSection}>
            <Heading level="h2">
              <Trans>Patienttyp</Trans>
            </Heading>
            {_(patientTypeDictionary[patient.patientType])}
          </section>
        ) : null}
      </>
    );
  };

  return (
    <div className={styles.information}>
      <DateOf />
      <section className={styles.informationSection}>
        <div className={styles.editableSection}>
          <Heading level="h2">
            <Trans>Namn</Trans>
          </Heading>
          {currentlyEditing !== "name" ? (
            <EditButton onClick={() => setCurrentlyEditing("name")}>
              <Trans>Ändra</Trans>
            </EditButton>
          ) : (
            <EditButton onClick={() => setCurrentlyEditing(null)}>
              <Trans>Avbryt</Trans>
            </EditButton>
          )}
        </div>
        {currentlyEditing === "name" ? (
          <EditName
            currentName={patient.name}
            onSuccess={() => setCurrentlyEditing(null)}
            patientId={patient.id}
          />
        ) : (
          patient.name
        )}
      </section>

      <section className={styles.informationSection}>
        <div className={styles.editableSection}>
          <Heading level="h2">
            <Trans>Adress</Trans>
          </Heading>
          {currentlyEditing !== "address" ? (
            <EditButton onClick={() => setCurrentlyEditing("address")}>
              <Trans>Ändra</Trans>
            </EditButton>
          ) : (
            <EditButton onClick={() => setCurrentlyEditing(null)}>
              <Trans>Avbryt</Trans>
            </EditButton>
          )}
        </div>
        {currentlyEditing === "address" ? (
          <EditAddress
            currentAddress={patient.address}
            onSuccess={() => setCurrentlyEditing(null)}
            patientId={patient.id}
          />
        ) : (
          <>
            <Address address={patient.address} showPostalCode />
            {patient.address.additionalInformation ? (
              <ul>
                {patient.address.additionalInformation
                  .split("\n")
                  .map((item, index) => (
                    <li key={item + index}>{item}</li>
                  ))}
              </ul>
            ) : undefined}
          </>
        )}
      </section>

      <section className={styles.informationSection}>
        <div className={styles.editableSection}>
          <Heading level="h2">
            <Trans>Telefonnummer</Trans>
          </Heading>
          {currentlyEditing !== "phonenumber" ? (
            <EditButton onClick={() => setCurrentlyEditing("phonenumber")}>
              <Trans>Ändra</Trans>
            </EditButton>
          ) : (
            <EditButton onClick={() => setCurrentlyEditing(null)}>
              <Trans>Avbryt</Trans>
            </EditButton>
          )}
        </div>
        {currentlyEditing === "phonenumber" ? (
          <EditPhoneNumber
            currentPhoneNumber={patient.phoneNumber}
            onSuccess={() => setCurrentlyEditing(null)}
            patientId={patient.id}
          />
        ) : (
          patient.phoneNumber
        )}
      </section>

      <section className={styles.informationSection}>
        <div className={styles.editableSection}>
          <Heading level="h2">
            <Trans>Allmän information</Trans>
          </Heading>
          {currentlyEditing !== "information" ? (
            <EditButton onClick={() => setCurrentlyEditing("information")}>
              <Trans>Ändra</Trans>
            </EditButton>
          ) : (
            <EditButton onClick={() => setCurrentlyEditing(null)}>
              <Trans>Avbryt</Trans>
            </EditButton>
          )}
        </div>
        {currentlyEditing === "information" ? (
          <EditInformation
            currentInformation={patient.information}
            onSuccess={() => setCurrentlyEditing(null)}
            patientId={patient.id}
          />
        ) : patient.information ? (
          <ul>
            {patient.information.split("\n").map((item, index) => (
              <li key={item + index}>{item}</li>
            ))}
          </ul>
        ) : (
          "-"
        )}
      </section>

      {cardiologyEnabled ? (
        <section className={styles.informationSection}>
          <div className={styles.editableSection}>
            <Heading level="h2">
              <Trans>Avdelning</Trans>
            </Heading>
            {currentlyEditing !== "ward" ? (
              <EditButton onClick={() => setCurrentlyEditing("ward")}>
                <Trans>Ändra</Trans>
              </EditButton>
            ) : (
              <EditButton onClick={() => setCurrentlyEditing(null)}>
                <Trans>Avbryt</Trans>
              </EditButton>
            )}
          </div>
          {currentlyEditing === "ward" ? (
            <EditWard
              currentWard={patient.ward}
              onSuccess={() => setCurrentlyEditing(null)}
              patientId={patient.id}
            />
          ) : (
            patient.ward.displayName
          )}
        </section>
      ) : null}
      <section className={styles.informationSection}>
        <div className={styles.editableSection}>
          <Heading level="h2">
            <Trans>Område</Trans>
          </Heading>
          {currentlyEditing !== "area" ? (
            <EditButton onClick={() => setCurrentlyEditing("area")}>
              <Trans>Ändra</Trans>
            </EditButton>
          ) : (
            <EditButton onClick={() => setCurrentlyEditing(null)}>
              <Trans>Avbryt</Trans>
            </EditButton>
          )}
        </div>
        {currentlyEditing === "area" ? (
          <EditArea
            currentArea={patient.area}
            onSuccess={() => setCurrentlyEditing(null)}
            patientId={patient.id}
          />
        ) : patient.area ? (
          patient.area.displayName
        ) : (
          "-"
        )}
      </section>

      <section className={styles.informationSection}>
        <Heading level="h2">
          <Trans>Anhöriga</Trans>
        </Heading>
        {patient.relatives.length >= 1 ? (
          <ul className={styles.relativeList}>
            {patient.relatives.map(
              ({
                name,
                phoneNumber,
                relation: relationUnknownCase,
                isLivingTogether,
              }) => {
                const relation = relationUnknownCase.toLocaleLowerCase();
                const patientName = patient.name;
                const optionalLivesTogetherWithSegment = isLivingTogether
                  ? t`bor med ${patientName} och`
                  : "";
                return (
                  <li key={name}>
                    <p>
                      <Trans>
                        {name} ({relation}) {optionalLivesTogetherWithSegment}{" "}
                        nås på {phoneNumber}
                      </Trans>
                    </p>
                  </li>
                );
              },
            )}
          </ul>
        ) : (
          "-"
        )}
        {currentlyEditing !== "add-relative" ? (
          <>
            <div className={styles.linedUpButtons}>
              <PlainButton onClick={() => setCurrentlyEditing("add-relative")}>
                <PlusIcon />
                <Trans>Lägg till anhörig</Trans>
              </PlainButton>
              {patient.relatives.length >= 1 ? (
                <DropdownMenu
                  trigger={{
                    text: t`Ta bort en anhörig`,
                    weight: "regular",
                  }}
                >
                  {patient.relatives.map((relative) => {
                    const relativeName = relative.name;
                    return (
                      <DropdownMenu.Item
                        action={() =>
                          removeRelativeMutation({
                            patientId: patient.id,
                            relativeId: relative.id,
                          })
                        }
                        content={t`Ta bort ${relativeName}`}
                        disabled={isRemovingRelative}
                        key={relative.id}
                      />
                    );
                  })}
                </DropdownMenu>
              ) : (
                <></>
              )}
            </div>
          </>
        ) : (
          <EditButton onClick={() => setCurrentlyEditing(null)}>
            <Trans>Avbryt</Trans>
          </EditButton>
        )}
        {currentlyEditing === "add-relative" ? (
          <AddRelative
            onSuccess={() => setCurrentlyEditing(null)}
            patientId={patient.id}
          />
        ) : undefined}
      </section>
      <section className={styles.informationSection}>
        <div className={styles.editableSection}>
          <Heading level="h2">
            <Trans>Sjukresenummer</Trans>
          </Heading>
          {currentlyEditing !== "healthcareJourneyNumber" ? (
            <EditButton
              onClick={() => setCurrentlyEditing("healthcareJourneyNumber")}
            >
              <Trans>Ändra</Trans>
            </EditButton>
          ) : (
            <EditButton onClick={() => setCurrentlyEditing(null)}>
              <Trans>Avbryt</Trans>
            </EditButton>
          )}
        </div>

        {currentlyEditing === "healthcareJourneyNumber" ? (
          <EditHealthcareJourneyNumber
            currentHealthcareJourneyNumber={patient.healthcareJourneyNumber}
            onSuccess={() => setCurrentlyEditing(null)}
            patientId={patient.id}
          />
        ) : patient.healthcareJourneyNumber ? (
          <p>{patient.healthcareJourneyNumber}</p>
        ) : (
          "-"
        )}
      </section>
      <section className={styles.informationSection}>
        <div className={styles.editableSection}>
          <Heading level="h2">
            <Trans>Trygghetslarm</Trans>
          </Heading>
          {currentlyEditing !== "safety-alarm" ? (
            <EditButton onClick={() => setCurrentlyEditing("safety-alarm")}>
              <Trans>Ändra</Trans>
            </EditButton>
          ) : (
            <EditButton onClick={() => setCurrentlyEditing(null)}>
              <Trans>Avbryt</Trans>
            </EditButton>
          )}
        </div>
        {currentlyEditing === "safety-alarm" ? (
          <EditSafetyAlarmText
            currentSafetyAlarmText={patient.safetyAlarmText}
            onSuccess={() => setCurrentlyEditing(null)}
            patientId={patient.id}
          />
        ) : patient.safetyAlarmText ? (
          <p>{patient.safetyAlarmText}</p>
        ) : (
          "-"
        )}
      </section>
    </div>
  );
};

export default Information;
