import { msg, t } from "@lingui/core/macro";
import { getPatientNameWithStatus, usePatient } from "@/api/Patients";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { Loading } from "@components/Loading/Loading";
import { deducedError } from "@/Utils/ErrorUtils";
import { Outlet, useParams } from "react-router";
import { z } from "zod";
import styles from "./Patient.module.scss";
import { Heading } from "@components/Heading/Heading";
import { Text } from "@components/Text/Text";
import { PersonalIdentityNumber } from "@/components/PersonalIdentityNumber/PersonalIdentityNumber";
import * as Sentry from "@sentry/react";
import { useLingui } from "@lingui/react";

export const Patient = () => {
  const { _ } = useLingui();

  const { patientId } = z.object({ patientId: z.string() }).parse(useParams());
  const { data: patient, isPending, isError, error } = usePatient(patientId);

  if (isPending) {
    return <Loading message={_(msg`Laddar patient`)} padding={24} />;
  }

  if (isError) {
    Sentry.captureException(error);
    return (
      <ErrorMessage
        message={`${t`Kunde inte hämta patientdetaljer.`} ${deducedError(error)}`}
        padding={24}
      />
    );
  }

  return (
    <>
      <div className={styles.pageTitle}>
        <Heading level="h1">{getPatientNameWithStatus(patient)}</Heading>
        <Text element="div" size="large">
          <PersonalIdentityNumber
            personalIdentityNumber={patient.personalIdentityNumber}
          />
        </Text>
      </div>
      <Outlet context={patient} />
    </>
  );
};
