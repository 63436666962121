import clsx from "clsx";
import { forwardRef, useId } from "react";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import styles from "./Checkbox.module.scss";

interface ICheckbox {
  errorMessage?: string;
  label: {
    text: string;
    component?: React.ReactNode;
  };
  name: string;
  visualStyle?: "normal" | "framed";
  value?: string;
  checkboxPosition?: "center" | "top";
}

const Checkbox = forwardRef<HTMLInputElement | null, ICheckbox>(
  (
    {
      errorMessage,
      label,
      name,
      value,
      visualStyle = "normal",
      checkboxPosition = visualStyle === "normal" ? "top" : "center",
      ...rest
    },
    ref,
  ) => {
    const id = useId();

    const Content = () => (
      <div
        className={clsx(
          styles.checkboxWrapper,
          styles[`visualStyle-${visualStyle}`],
          styles[`checkboxPosition-${checkboxPosition}`],
        )}
      >
        <label htmlFor={id}>
          <span className={label.component ? "visuallyHidden" : undefined}>
            {label.text}
          </span>
          {label.component}
        </label>
        <input
          aria-label={label.component !== undefined ? label.text : undefined}
          id={id}
          type="checkbox"
          aria-invalid={errorMessage ? true : undefined}
          name={name}
          ref={ref}
          value={value ? value : undefined}
          {...rest}
        />
      </div>
    );

    return errorMessage ? (
      <div className={styles.errorMessageWrapper}>
        <ErrorMessage message={errorMessage} weight="bold" />
        <Content />
      </div>
    ) : (
      <Content />
    );
  },
);
Checkbox.displayName = "Checkbox";

export default Checkbox;
