import styles from "./NotificationCircle.module.scss";
import clsx from "clsx";

export const NotificationCircle = ({
  children,
  placement = "top-left",
  isVisible,
  number,
}: {
  children?: React.ReactNode;
  placement?: "top-left" | "left" | "top-right" | "right";
  isVisible: boolean;
  number?: number;
}) => {
  const showNumber = !!number;

  if (children === undefined) {
    return isVisible ? (
      showNumber ? (
        <div className={styles.circleWithNumber}>{number}</div>
      ) : (
        <div className={styles.circle}></div>
      )
    ) : (
      <></>
    );
  } else {
    return (
      <div className={styles.wrapper}>
        {isVisible ? (
          showNumber ? (
            <div
              className={clsx([
                styles.circleWithNumber,
                styles[`placement-${placement}`],
              ])}
            >
              {number}
            </div>
          ) : (
            <div
              className={clsx([
                styles.circle,
                styles[`placement-${placement}`],
              ])}
            ></div>
          )
        ) : (
          <></>
        )}
        {children}
      </div>
    );
  }
};
export default NotificationCircle;
