import { msg } from "@lingui/core/macro";
import type { MessageDescriptor } from "@lingui/core";
import { z } from "zod";

export const recurrencesPerDayOptions = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
] as const;

export const recurrencesPerDayOptionSchema = z.enum(recurrencesPerDayOptions);
export type IRecurrencesPerDayOption = z.infer<
  typeof recurrencesPerDayOptionSchema
>;

export const timeSensitivityOptions = [
  "0.25",
  "0.5",
  "1",
  "2",
  "4",
  "12",
] as const;

export const timeSensitivityOptionSchema = z.enum(timeSensitivityOptions);
export type ITimeSensitivityOption = z.infer<
  typeof timeSensitivityOptionSchema
>;

export const timeSensitivityDictionary: Record<
  ITimeSensitivityOption,
  MessageDescriptor
> = {
  "0.25": msg`+15min`,
  "0.5": msg`+30min`,
  "1": msg`+1h`,
  "2": msg`+2h`,
  "4": msg`+4h`,
  "12": msg`+12h`,
};
