import clsx from "clsx";
import type { LinkProps } from "react-router";
import { Link as BaseLink } from "react-router";
import styles from "./Link.module.scss";
import type { IText } from "../Text/Text";

export type ILink = LinkProps & {
  to: string;
  type?: "internal" | "external" | "phone" | "email";
} & Pick<IText, "size" | "weight">;

export const Link = ({
  children,
  className,
  type = "internal",
  to,
  size,
  weight = "medium",
  ...rest
}: LinkProps & React.RefAttributes<HTMLAnchorElement> & ILink) => {
  const classNames = clsx(
    styles.link,
    styles[`size-${size}`],
    styles[`weight-${weight}`],
    className,
  );
  return type === "external" ? (
    <a
      className={classNames}
      href={to}
      target="_blank"
      rel="noreferrer"
      {...rest}
    >
      {children}
    </a>
  ) : type === "phone" ? (
    <a className={classNames} href={`tel:${to}`} {...rest}>
      {children}
    </a>
  ) : type === "email" ? (
    <a className={classNames} href={`mailto:${to}`} {...rest}>
      {children}
    </a>
  ) : (
    <BaseLink className={classNames} to={to} {...rest}>
      {children}
    </BaseLink>
  );
};

export type IUnstyledLink = LinkProps &
  React.RefAttributes<HTMLAnchorElement> & {
    type?: "internal" | "external" | "phone" | "email";
    to: string;
  };

export const UnstyledLink = ({
  children,
  type = "internal",
  to,
  ...rest
}: IUnstyledLink) => {
  return type === "external" ? (
    <a
      href={to}
      className={styles.unstyledLink}
      target="_blank"
      rel="noreferrer"
      {...rest}
    >
      {children}
    </a>
  ) : type === "phone" ? (
    <a href={`tel:${to}`} className={styles.unstyledLink} {...rest}>
      {children}
    </a>
  ) : type === "email" ? (
    <a href={`mailto:${to}`} className={styles.unstyledLink} {...rest}>
      {children}
    </a>
  ) : (
    <BaseLink to={to} className={styles.unstyledLink} {...rest}>
      {children}
    </BaseLink>
  );
};
