import styles from "./DetailsPopover.module.scss";
import {
  Dialog,
  Popover,
  type DialogProps,
  type PopoverProps,
} from "react-aria-components";

type IDetailsPopover = {
  trigger?: React.ReactNode;
  children: React.ReactNode;
  popoverProps?: PopoverProps;
  dialogProps?: DialogProps;
};

export const DetailsPopover = ({
  children,
  popoverProps,
  dialogProps,
}: IDetailsPopover) => {
  return (
    <Popover {...popoverProps}>
      <Dialog className={styles.dialog} {...dialogProps}>
        {children}
      </Dialog>
    </Popover>
  );
};
