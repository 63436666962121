import { t } from "@lingui/core/macro";
import { useCenterBaseUrl } from "@/Utils/useCenterBaseUrl";
import { GroupDetails } from "@/pages/commandcenter/Patients/Patient/Activities/GroupDetails/GroupDetails";
import { Dialog } from "@components/Dialog/Dialog";
import { useNavigate } from "react-router";

export const GroupDetailsDialog = () => {
  const navigate = useNavigate();
  const centerBaseUrl = useCenterBaseUrl();

  return (
    <Dialog
      isOpen={true}
      onClose={() => {
        navigate(`${centerBaseUrl}/activities`);
      }}
      title={t`Besöksdetaljer`}
      hideTitle
    >
      <GroupDetails showPatientName showCloseButton={false} />
    </Dialog>
  );
};
