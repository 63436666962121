import { Trans } from "@lingui/react/macro";
import { Tab, TabList, TabPanel, Tabs } from "@components/Tabs";
import styles from "./ActivityTabs.module.scss";
import { Outlet } from "react-router";

export const ActivityTabs = () => {
  return (
    <div className={styles.tabList}>
      <Tabs>
        <TabList>
          <Tab to="singleOccurrence">
            <Trans>Tillfälle</Trans>
          </Tab>
          <Tab to="recurringOccurrence">
            <Trans>Serie</Trans>
          </Tab>
        </TabList>
        <TabPanel>
          <Outlet />
        </TabPanel>
      </Tabs>
    </div>
  );
};
