import { Trans } from "@lingui/react/macro";
import {
  activityRequirementSchema,
  type IActivityRequirement,
} from "@/api/Activities";
import type { IChip } from "./Chip";
import Chip from "./Chip";
import { CompetenceChip } from "./CompetenceChip";
import type { IActivityRequirementStatus } from "@models/activities";
import { getBackground, getBorder } from "./chipUtils";

export const RequirementChip = ({
  requirement,
  state,
  size,
}: {
  requirement: IActivityRequirement;
  state: IActivityRequirementStatus;
  content?: string;
  onRemove?: () => void;
} & Pick<IChip, "size">) => {
  if (requirement === activityRequirementSchema.Values.DoubleStaffing) {
    return (
      <Chip
        color="gray"
        background={getBackground(state)}
        border={getBorder(state)}
        size={size}
      >
        <Trans>2x</Trans>
      </Chip>
    );
  }

  return <CompetenceChip competence={requirement} state={state} size={size} />;
};
