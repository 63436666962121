import { t } from "@lingui/core/macro";
import {
  patientStatusSchema,
  type IDeletedPatient,
  type IExistingPatient,
} from "@models/patients";
import { Text } from "@components/Text/Text";
import { i18n } from "@lingui/core";

export const DateOfBirth = ({
  patient,
}: {
  patient:
    | IDeletedPatient
    | Pick<IExistingPatient, "personalIdentityNumber" | "status">;
}) => {
  return (
    <Text element="div" color="faded" size="small">
      {patient.status === patientStatusSchema.Values.deleted
        ? t`Okänt födelsedatum`
        : i18n.date(
            new Date(
              `${patient.personalIdentityNumber.substring(
                0,
                4,
              )}-${patient.personalIdentityNumber.substring(
                4,
                6,
              )}-${patient.personalIdentityNumber.substring(6, 8)}`,
            ),
          )}
    </Text>
  );
};
